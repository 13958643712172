import { useContext } from "react";

import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { Sidebar } from "../../ui/Modal";
import { ItemsContext } from "../../../data/context";
import { HamburgIcon, HamburgIconLight, SiteLogo } from "../../Icons";

const Navbar = ({ ...props }) => {
  let navigate = useNavigate();

  const { isSideBarOpen, setIsSideBarOpen } = useContext(ItemsContext);

  const openModal = () => {
    setIsSideBarOpen(true)
  }

  const closeModal = () => {
    setIsSideBarOpen(false)
  }

  return (
    <>
      <div className={clsx(props.darkMenu || props.darklightMenu ?
        "bg-gray" :
        "bg-white",
        "px-4 md:px-14 w-full py-1 fixed top-0 z-10 flex justify-between lg:bg-transparent lg:backdrop-blur-none"
      )}
      >
        <div>
          <SiteLogo
            onClick={() => navigate(`/`)}
            className={clsx(props.darkMenu || props.darklightMenu ? "text-white" : "text-gray", "cursor-pointer w-8 sm:w-12 h-full")}
          />
        </div>
        <div className="flex justify-center items-center">
          {props.darkMenu ?
            <HamburgIconLight className="cursor-pointer w-6 sm:w-[38px] h-6 sm:h-[38px]" onClick={openModal} />
            :
            <HamburgIcon className="cursor-pointer w-6 sm:w-[38px] h-6 sm:h-[38px]" onClick={openModal} />
          }
        </div>
      </div>

      <Sidebar isOpen={isSideBarOpen} closeModal={closeModal} />
    </>
  );
};

export default Navbar;
