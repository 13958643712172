import React, { useEffect, useState } from 'react'
import { Button, JoinUs } from '../component/ui'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import url from '../config';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { StarIcon } from '../component/Icons';

type FormData = {
    choices: [],
    name: string;
    title: string;
    type: string;
}

const NONPTSuvery = () => {

    let navigate = useNavigate();
    const { register, handleSubmit, watch } = useForm();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ipAddress, setIPAddress] = useState("");
    const [state, setState] = useState("");

    const onSubmit = (data: any) => {
        console.log(data);
        setLoading(true)
        let fowndData: any = localStorage.getItem("fownd");
        let body: any = {
            surveyResult: data,
            surveyResultText: JSON.stringify(data),
            state: state,
            ipAddress: ipAddress,
        }
        fowndData = JSON.parse(fowndData)
        if (fowndData?.email) {
            body['email'] = fowndData?.email
        }
        console.log(body)
        axios
            .post(`${url}/survey-nonpt`, body)
            .then((res) => {
                setLoading(false)
                localStorage.removeItem("fownd-NONPT")
                toast.success("Survey Submitted Successfully");
                navigate("/success")
            })
            .catch((err) => {
                console.log(err, "err");
                setLoading(false)
                toast.error("Something went Wrong");
            });
    }

    useEffect(() => {
        setLoading(true)
        axios
            .get(`${url}/survey`)
            .then((res) => {
                setLoading(false)
                setData(res?.data[2]?.pages[0]?.elements);
            })
            .catch((err) => {
                console.log(err, "err");
                setLoading(false)
                toast.error("Something went Wrong");
            });

    }, []);

    useEffect(() => {
        const fetchIPAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIPAddress(response.data.ip);
                const ipAddress = await axios.get("https://api64.ipify.org/?format=json")
                const latlong = await axios.get(`https://ipapi.co/${ipAddress?.data?.ip}/json`);
                await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlong.data?.latitude},${latlong.data?.longitude}&key=AIzaSyBH-06rTud2tbw9Oi_r7kKL_z_mq5yzsJg`)
                    .then(response => {
                        const results: any = response?.data?.results;
                        if (results && results.length > 0) {
                            // Extract the state and country from the response
                            const addressComponents = results[0].address_components;
                            let state, country;
                            for (const component of addressComponents) {
                                const types = component.types;
                                if (types.includes('administrative_area_level_1')) {
                                    state = component.long_name;
                                } else if (types.includes('country')) {
                                    country = component.long_name;
                                }
                            }
                            setState(state);
                            // console.log('State:', state);
                            console.log('Country:', country);
                        } else {
                            console.log('No results found');
                        }
                    })
                    .catch(error => {
                        console.error('Reverse geocoding error:', error);
                    });
            } catch (error) {
                console.log('Error fetching IP address:', error);
            }
        };
        fetchIPAddress();
    }, []);

    return (
        <div>
            <div className="flex flex-col justify-center items-center md:pt-0 lg:pt-[88px] pt-[120px]">
                <div className="text-center">
                    <div className="font-bold text-2xl font-inter uppercase">Survey questions</div>
                    <div className="font-normal text-xl font-dmSans">Please answer all the questions</div>
                    <div className="font-normal text-base text-gray font-dmSans">While answering this, we would get some more insights about your goals and objectives</div>
                    <div className="mt-7 flex justify-center">
                        <JoinUs />
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="mb-3">
                    <div className="bg-porcelain rounded-lg max-w-2xl mt-16 mx-auto">
                        <div className="shadow-lg px-2 py-2 flex justify-end">
                            <Button
                                className="bg-gray text-white"
                                type="submit"
                                loading={loading}
                            >
                                Done
                            </Button>
                        </div>
                        <div className="mt-5 px-10 py-10 overflow-y-auto lg:h-[600px] h-[545px]">
                            <div>
                                {data?.map((question: FormData, i) => {
                                    return (
                                        <div key={i} className="mb-5">
                                            <div className="flex items-center space-x-3 font-normal text-base text-gray font-dmSans list-outside mb-4">
                                                <div className="rounded-full bg-gray text-white px-4 py-2">{i + 1}</div>
                                                <div>{question?.title}</div>
                                            </div>
                                            <div className="lg:flex lg:flex-wrap lg:space-x-3 pl-12">
                                                {question?.choices.map((option: any, index: number) => {
                                                    return (
                                                        <div key={index} className="mb-4">
                                                            {question?.type === "checkbox" && (
                                                                <label className={clsx(watch(question?.name)?.includes(option)
                                                                    ? "bg-gray text-white"
                                                                    : "bg-white text-gray",
                                                                    "rounded-full border-gray px-4 py-2 cursor-pointer"
                                                                )}>
                                                                    <input
                                                                        value={option}
                                                                        type="checkbox"
                                                                        hidden
                                                                        className="mr-2"
                                                                        {...register(`${question?.name}`, { required: false })}
                                                                    />
                                                                    {option}
                                                                </label>
                                                            )}
                                                            {question?.type === "radiogroup" && (
                                                                <label className={
                                                                    clsx(watch(question?.name)?.includes(option)
                                                                        ? "bg-gray text-white"
                                                                        : "bg-white text-gray",
                                                                        "rounded-full border-gray px-4 py-2 cursor-pointer"
                                                                    )}>
                                                                    <input
                                                                        type="radio"
                                                                        value={option}
                                                                        hidden
                                                                        className="mr-2"
                                                                        {...register(`${question?.name}`, { required: false })}
                                                                    />
                                                                    {option}
                                                                </label>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                                {question?.type === "textarea" && (
                                                    <textarea
                                                        className="w-full border-[0.5px] text-gray focus:outline-none focus:border-[0.5px] border-gray focus:border-gray p-2 font-semibold rounded-md"
                                                        rows={4}
                                                        {...register(`${question?.name}`, { required: false })}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {/* <div>
                                <div className="lg:grid lg:grid-cols-2 lg:gap-3 lg:px-2 lg:py-2 px-5 py-5">
                                    <div className="rounded-lg bg-white max-w-xs px-3 py-5 mb-4">
                                        <div className="flex items-center space-x-5 pb-4 border-b border-gray">
                                            <img src="/assets/images/icons/discord.png" alt="discord" />
                                            <div className="font-dmSans font-bold text-base text-black">
                                                Discord
                                            </div>
                                        </div>
                                        <div className="flex items-center space-x-5 pb-2 mt-5">
                                            <StarIcon />
                                            <div className="font-dmSans font-normal text-xs text-black">Securely communicate, Influence features and test the platform</div>
                                        </div>
                                        <div className="mt-6 flex flex-col justify-center items-center">
                                            <button
                                                className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full w-full h-9"
                                                type="button"
                                                onClick={() => window.open("https://discord.gg/VcjDkXAwAt")}
                                            >
                                                Join
                                            </button>
                                            <div className="flex items-center">
                                                <div>
                                                    <img src="/assets/images/icons/light-bulb.svg" alt="light-bulb" />
                                                </div>
                                                <div
                                                    className="text-[#6C63FF] font-dmSans font-bold text-xs underline"
                                                    onClick={() => window.open("https://www.youtube.com/watch?v=TJ13BA3-NR4")}
                                                >
                                                    Learn about discord
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-lg bg-white max-w-xs px-3 py-5 mb-4">
                                        <div className="flex items-center space-x-5 pb-4 border-b border-gray">
                                            <img src="/assets/images/icons/facebook.png" alt="discord" />
                                            <div className="font-dmSans font-bold text-base text-black">
                                                Facebook
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center space-x-5 pb-2 mt-5 mb-4">
                                            <div className="font-dmSans font-normal text-xs text-black">Support us, keep in touch and be in the know</div>
                                        </div>
                                        <div className="mt-6">
                                            <button
                                                type="button"
                                                className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full w-full h-9"
                                                onClick={() => window.open("http://www.facebook.com/groups/fownd/")}
                                            >
                                                Join
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NONPTSuvery