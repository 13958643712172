import React from "react";
import { useNavigate } from "react-router-dom";

const Intro = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="xl:flex justify-center space-x-20 items-center pt-36 px-4 lg:px-28 bg-gray pb-3 lg:pb-0">
        <div className="max-w-3xl lg:mb-32 mb-8">
          <div className="lg:block hidden font-productSans font-normal text-start text-8xl leading-[119px] text-cyan-blue">
            Fownding Modern
            <span className="font-productSans font-normal text-8xl leading-[119px] text-white pl-4">
              Physical Therapy!
            </span>
          </div>
          <div className="lg:hidden block font-productSans font-normal text-center text-4xl text-white">
            Fownding Modern Physical Therapy!
          </div>
          <div className="lg:block hidden font-productSans font-normal text-4xl text-cyan-blue mt-16">
            Intuitive, AI-assisted clinical SOAP notes. Let tech take care of
            your documentation, and you take care of your patients.
          </div>
          {/* <div className="lg:block hidden font-productSans font-normal text-4xl text-cyan-blue mt-16">
          Autonomy, flexibility and satisfaction for both providers and
          patients. Practice on an App in minutes with zero capital or need for
          brick or mortar.{" "}
          <span className="text-crayola">Earn 2 to 3 times</span> more than
          outpatient work!
        </div> */}
          <div className="block lg:hidden text-center font-productSans font-bold text-base text-cyan-blue mt-6">
            Intuitive, AI-assisted clinical SOAP notes. Let tech take care of
            your documentation, and you take care of your patients.
          </div>
          {/* <div className="block lg:hidden text-center font-productSans font-bold text-base text-cyan-blue mt-6">
          Autonomy, flexibility and satisfaction for both providers and
          patients. Practice on an App in minutes with zero capital or need for
          brick or mortar. <span className="text-crayola">Earn 2 to 3 times</span> more than outpatient work!
        </div> */}
        </div>
        <div className="lg:mb-32">
          <img src="/assets/images/share/header-image.png" alt="fownd" />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center px-4 lg:px-28 bg-gray pb-3 lg:pb-0">
        <div className="text-2xl lg:text-6xl text-center text-crayola font-bold mt-5 mb-5">
          A.I Clinical Note Solutions Coming Soon
        </div>
        <div className="text-xl text-center text-crayola font-bold mb-5 cursor-pointer underline" onClick={() => navigate(`/invest`)}>
          Reach US
        </div>
      </div>
    </>
  );
};

export default Intro;
