import React from "react";

export interface ProgressbarProps {
    width?: string | number;
}

const Progressbar: React.FC<ProgressbarProps> = (props) => {
    const {
        width = 10,
        ...rest
    } = props;
    return (
        <div
            className="bg-gray h-2.5 rounded-full"
            style={{
                width: width,
            }}
            {...rest}
        >
        </div>
    );
};

export default Progressbar;