import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const TeamDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();

  const [detail, setDetail] = useState({
    name: "",
    image: "",
  });

  useEffect(() => {
    if (name === "vignesh") {
      setDetail({
        name: "VIGNESH RAMA",
        image: "/assets/images/teams/vignesh.png",
      });
    }
    if (name === "christina") {
      setDetail({
        name: "CHRISTINA RAMA",
        image: "/assets/images/teams/chrisina.png",
      });
    }
    if (name === "sowmya") {
      setDetail({
        name: "SOWMYA SHETTY",
        image: "/assets/images/teams/sowmya.png",
      });
    }
    if (name === "parini") {
      setDetail({
        name: "PARINI PAREKH",
        image: "/assets/images/teams/parini.png",
      });
    }
    if (name === "amy") {
      setDetail({
        name: "AMY ELDER",
        image: "/assets/images/teams/amy-elder.png",
      });
    }
  }, [name]);

  return (
    <section className="relative bg-gray pt-[88px] xl:pt-0">
      <div className="h-12 flex items-center px-6 md:px-16 xl:hidden">
        <button
          type="button"
          onClick={() => navigate("/about")}
          className="material-icons-round text-white"
        >
          chevron_left
        </button>
      </div>
      <button
        type="button"
        className="xl:inline-flex items-center space-x-3 text-white absolute top-40 left-48 z-10 mb-0 hidden"
        onClick={() => navigate("/about")}
      >
        <span className="material-icons-round">chevron_left</span>
        <span className="underline text-xl font-archivo">Back</span>
      </button>
      <div className="team-detail-section min-h-screen xl:block hidden">
        <div className="flex items-center justify-center absolute inset-0 left-[6.2rem] 2xl:left-[14.1rem]">
          <div className="w-72">
            <div className="text-white font-medium font-archivo text-3xl text-right">
              {detail?.name}
            </div>
          </div>
          <div className="w-[364px] h-[365px] bg-white flex-none flex items-center justify-center mx-10">
            <img
              src={detail?.image}
              alt={detail?.name}
              className="w-[282px] h-[282px] rounded-[20px]"
            />
          </div>
          <div className="max-w-sm 2xl:max-w-lg">
            {name === "vignesh" && (
              <div className="text-white font-dmSans text-base space-y-4">
                <p>
                  I have two decades of experience building web-scale enterprise
                  technologies for Fortune 500.
                </p>
                <p>
                  I help incubate, scale and take to market new horizon
                  technologies in AI, Virtualization, and Networking. FOWND as a
                  concept can truly transform the Physical Therapy industry,
                  benefiting the profession and patients.
                </p>
                <p>
                  The amalgamation of SaaS technologies and gig economy concepts
                  in healthcare are going to be the catalyst that FOWND brings
                  to bear, to become the gold standard for all healthcare.
                </p>
              </div>
            )}
            {name === "christina" && (
              <div className="text-white font-dmSans text-base space-y-4">
                <p>
                  I have worked in outpatient, inpatient, neuro rehab, home
                  health, and SNFs. My experience in various states across the
                  US gives me the unique perspective to disrupt the industry
                  with innovation.{" "}
                </p>
                <p>
                  Improving lives is at the core of physical therapy, we need to
                  take better care of both therapists and patients. We intend to
                  change how patients and therapists connect to improve overall
                  therapist (provider) sentiment. With FOWND, I have set out to
                  address a severely lacking technology, innovation, and
                  professional satisfaction gap. Our Mission is to provide
                  high-quality, flexible care!
                </p>
              </div>
            )}
            {name === "sowmya" && (
              <div className="text-white font-dmSans text-base space-y-4">
                <p>
                  My education and experience in healthcare was pivotal in
                  helping form FOWND’s vision and being part of the team in
                  fulfilling the mission to better the healthcare system for
                  providers and patients, one step at a time.
                </p>
                <p>
                  I bring the zeal to learn, adaptability and I wear the
                  orchestrator hat for the team.
                </p>
              </div>
            )}
            {name === "parini" && (
              <div className="text-white font-dmSans text-base space-y-4">
                <p>
                  As a Masters of Design graduate, I have passion for early
                  stage, industry change agent startups. Health Tech needs a
                  modern, minimalist yet sophisticated user experience and
                  design elements.
                </p>
                <p>
                  With FOWND my aim is to simplify the complex interactions
                  between providers, patients and ecosystem of health care
                  technologies.
                </p>
              </div>
            )}
            {name === "amy" && (
              <div className="text-white font-dmSans text-base space-y-4">
                <p>
                  We at FOWND are attempting to ‘fix’ the documentation
                  ‘problem’ that is at the top of the list if you were to ask
                  any PT what could be improved within the field.
                </p>
                <p>
                  I graduated from PT school in 2007 and worked in outpatient
                  orthopedics for all my practicing years before a major health
                  event forced me to pivot into a non-clinical role.I spent 7+
                  years in the role of Clinical Documentation Specialist for a
                  private outpatient orthopedic practice. Throughout that time,
                  I became more and more disillusioned by the increasing demands
                  of documentation, requiring therapists to write more and more
                  on each note to justify payment for their services.With the
                  constant decrease in reimbursement rates for our services, PTs
                  must put more and more patients on their schedule just to make
                  ends meet.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="team-detail-section pb-14 min-h-[calc(100vh-136px)] block xl:hidden">
        <div className="w-[215px] h-[195px] sm:w-[364px] sm:h-[365px] bg-white flex items-center justify-center mx-auto">
          <img
            src={detail?.image}
            alt={detail?.name}
            className="h-[147px] w-[147px] sm:w-[282px] sm:h-[282px] rounded-md sm:rounded-[20px]"
          />
        </div>
        <div className="pt-5 max-w-[185px] sm:max-w-xs mx-auto">
          <div className="text-white font-medium font-archivo text-xl sm:text-3xl text-center mb-7">
            {detail?.name}
          </div>
          {name === "vignesh" && (
            <div className="text-white font-dmSans text-xs sm:text-base space-y-4">
              <p>
                I have two decades of experience building web-scale enterprise
                technologies for Fortune 500.
              </p>
              <p>
                I help incubate, scale and take to market new horizon
                technologies in AI, Virtualization, and Networking. FOWND as a
                concept can truly transform the Physical Therapy industry,
                benefiting the profession and patients.
              </p>
              <p>
                The amalgamation of SaaS technologies and gig economy concepts
                in healthcare are going to be the catalyst that FOWND brings to
                bear, to become the gold standard for all healthcare.
              </p>
            </div>
          )}
          {name === "christina" && (
            <div className="text-white font-dmSans text-xs sm:text-base space-y-4">
              <p>
                I have worked in outpatient, inpatient, neuro rehab, home
                health, and SNFs. My experience in various states across the US
                gives me the unique perspective to disrupt the industry with
                innovation.{" "}
              </p>
              <p>
                Improving lives is at the core of physical therapy, we need to
                take better care of both therapists and patients. We intend to
                change how patients and therapists connect to improve overall
                therapist (provider) sentiment. With FOWND, I have set out to
                address a severely lacking technology, innovation, and
                professional satisfaction gap. Our Mission is to provide
                high-quality, flexible care!
              </p>
            </div>
          )}
          {name === "sowmya" && (
            <div className="text-white font-dmSans text-xs sm:text-base space-y-4">
              <p>
                My education and experience in healthcare was pivotal in helping
                form FOWND’s vision and being part of the team in fulfilling the
                mission to better the healthcare system for providers and
                patients, one step at a time.
              </p>
              <p>
                I bring the zeal to learn, adaptability and I wear the
                orchestrator hat for the team.
              </p>
            </div>
          )}
          {name === "parini" && (
            <div className="text-white font-dmSans text-xs sm:text-base space-y-4">
              <p>
                As a Masters of Design graduate, I have passion for early stage,
                industry change agent startups. Health Tech needs a modern,
                minimalist yet sophisticated user experience and design
                elements.
              </p>
              <p>
                With FOWND my aim is to simplify the complex interactions
                between providers, patients and ecosystem of health care
                technologies.
              </p>
            </div>
          )}
          {name === "amy" && (
            <div className="text-white font-dmSans text-xs sm:text-base space-y-4">
              <p>
                We at FOWND are attempting to ‘fix’ the documentation ‘problem’
                that is at the top of the list if you were to ask any PT what
                could be improved within the field.
              </p>
              <p>
                I graduated from PT school in 2007 and worked in outpatient
                orthopedics for all my practicing years before a major health
                event forced me to pivot into a non-clinical role.I spent 7+
                years in the role of Clinical Documentation Specialist for a
                private outpatient orthopedic practice. Throughout that time, I
                became more and more disillusioned by the increasing demands of
                documentation, requiring therapists to write more and more on
                each note to justify payment for their services.With the
                constant decrease in reimbursement rates for our services, PTs
                must put more and more patients on their schedule just to make
                ends meet.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
