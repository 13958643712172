import React, { useRef } from "react";
import useChart from "../../../Hooks/useChart";

export interface PieChartProps {
    className?: any;
    values: string[];
    label: string[];
    id: string;
}

const PieChart: React.FC<PieChartProps> = (props) => {

    const {
        className,
        values,
        label,
        id,
        ...rest
    } = props;
    const canvasRef = useRef(null);
    console.log(values, "values")
    console.log(label, "label")

    useChart(canvasRef, {
        type: "pie",
        data: {
            labels: label,
            datasets: [
                {
                    data: values,
                    backgroundColor: [
                        '#DECD84',
                        '#2F2E41',
                        '#FFFFFF'
                    ],
                    borderWidth: 0,
                },
            ],
        },
        option: {
            height: 200,
            width: 200
        }
    });

    return (
        <div className="App px-2 py-2 max-h-40 flex flex-col justify-center items-center">
            <h1 className="text-xs text-center lg:text-base font-dmSans font-normal text-white">Burn out</h1>
            <canvas id={id} ref={canvasRef} {...rest} />
        </div>
    );
}

export default PieChart;
