import React from "react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  title: string;
  placeholder?: string;
  option: string[];
  onChange?: (...args: any[]) => any;
}

const InputSelect: React.FC<InputProps> = (props) => {
  const { title, placeholder, onChange, value, option } = props;

  const handleOnChange = (e: any) => {
    if (onChange) {
      onChange(e.target.value);
    }
    return null;
  };

  return (
    <div className="grid space-y-2 xl:my-4">
      <label
        htmlFor={title}
        className="text-gray font-bold text-xs font-dmSans"
      >
        <p className="mb-1 capitalize font-dmSans text-base font-bold">{title}</p>
        <select
          name={title}
          id={title.trim()}
          autoCapitalize="off"
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          className="w-full border-[0.5px] bg-white text-black focus:outline-none focus:border-[0.5px] border-gray focus:border-black p-2 font-semibold rounded-md"
        >
          {option.map((list, index) => {
            return (
              <option key={index} value={list}>
                {list}
              </option>
            );
          })}
        </select>
      </label>
    </div>
  );
};

export default InputSelect;
