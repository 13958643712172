import React, { PropsWithChildren, createContext, useState } from 'react';

interface formData {
  key: any;
  name: string;
  type: string;
  action?: string;
}

interface ContextProps {
  items: formData[];
  addItem: (item: any) => void;
  isSideBarOpen: boolean;
  setIsSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isPolicyModel: boolean;
  setIsPolicyModelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ptQuestion: string;
  setPTQuestion: React.Dispatch<React.SetStateAction<string>>;
}

const initialContext: ContextProps = {
  items: [],
  addItem: () => { },
  isSideBarOpen: false,
  setIsSideBarOpen: () => { },
  isPolicyModel: false,
  setIsPolicyModelOpen: () => { },
  ptQuestion: "",
  setPTQuestion: () => { },
};

interface MyComponentProps extends PropsWithChildren<{}> {
  // Define any additional props for your component here
}

export const ItemsContext = createContext<ContextProps>(initialContext);

const ItemsProvider: React.FC<MyComponentProps> = ({ children }) => {
  const [items, setItems] = useState<formData[]>([]);
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [isPolicyModel, setIsPolicyModelOpen] = useState<boolean>(false);
  const [ptQuestion, setPTQuestion] = useState<string>("");

  const addItem = (item: any) => {
    const myObject = items.find((obj) => obj.name === item?.name);
    console.log(myObject?.key, "myObject?.key")
    if (!myObject) {
      if (item?.type === 'checkbox') {
        let newString = [];
        newString.push(item?.key)
        item.key = newString
      }
      setItems([...items, item]);
    } else {
      if (item?.type === 'checkbox') {
        if (item?.action === "remove") {
          myObject?.key?.splice(myObject?.key.indexOf(item.key), 1);
          item = {}
        } else {
          let newString = [];
          newString.push(item?.key)
          for (let i = 0; i < myObject?.key.length; i++) {
            const element = myObject?.key[i];
            if (element?.length > 5) {
              newString.push(element)
            }
          }
          item.key = newString
        }
      }
      const newObjects = items.filter((obj) => obj.name !== item?.name);
      setItems([...newObjects, item]);
    }
  };

  return (
    <ItemsContext.Provider value={{
      items,
      addItem,
      isSideBarOpen,
      setIsSideBarOpen,
      isPolicyModel,
      setIsPolicyModelOpen,
      ptQuestion,
      setPTQuestion
    }}>
      {children}
    </ItemsContext.Provider>
  );
};

export default ItemsProvider;
