import { useNavigate } from "react-router-dom";

import { StarIcon } from "../component/Icons";

const Invest = () => {
  let navigate = useNavigate();

  return (
    <section className="bg-gray 2xl:h-screen items-center md:pt-24 lg:pt-[88px] pt-[78px] pb-3">
      <div className="lg:mx-32 mx-4">
        <div className="xl:grid xl:grid-cols-3 xl:gap-5">
          <div className="bg-white/25 rounded-lg px-5 py-5 flex flex-col items-center mb-3">
            <div className="font-archivo font-bold lg:text-xl text-base text-crayola">
              Join the growing team
            </div>
            <div className="mt-12">
              <img
                src="/assets/images/teams/team.svg"
                alt="fownd-team"
                className="lg:h-32 h-20 w-auto object-contain"
              />
            </div>
            <div className="md:h-28 lg:h-72 lg:mt-12 mt-3 font-dmSans font-normal text-white lg:text-xl text-xs text-center lg:px-8">
              We are always looking for talented, passionate transformation
              agents in the world. If you are a healthcare professional,
              computer scientist, developer, data scientist, AI specialist,
              looking for the biggest challenge in health tech, reach out to us!
            </div>
            <div className="mt-3">
              <button
                type="button"
                className=" bg-black inline-flex h-10 select-none items-center lg:text-base text-xs justify-center w-44 text-white px-7 font-medium leading-5 outline-none rounded-full cursor-pointer"
                onClick={() => navigate(`/contact-us`)}
              >
                Join The Team
              </button>
            </div>
          </div>
          <div className="bg-white/25 rounded-lg px-5 py-5 flex flex-col items-center mb-3">
            <div className="font-archivo font-bold lg:text-xl text-base text-crayola">
              Help us Scale
            </div>
            <div className="mt-12">
              <img
                src="/assets/images/teams/scale.svg"
                alt="fownd-team"
                className="lg:h-32 h-20 w-auto object-contain"
              />
            </div>
            <div className="md:h-28 lg:h-72 lg:mt-12 mt-3 font-dmSans font-normal text-white lg:text-xl text-xs text-center lg:px-8">
              <p>
                Are you a visionary looking to become a catalyst to an industry
                yearning for a technology revolution ?
              </p>
              <p className="mt-7">
                Join us by investing in our growth and help us innovate and
                disrupt rapidly.
              </p>
            </div>
            {/* <div className="h-30 lg:mt-12 font-dmSans font-normal text-white lg:text-xl text-xs text-center lg:px-8">
              Are you a visionary looking to become a catalyst to an industry yearning for a technology revolution ?
            </div>
            <div className="h-30 lg:mt-12 pt-5 font-dmSans font-normal text-white lg:text-xl text-xs text-center lg:px-8">
              Join us by investing in our growth and help us innovate and disrupt rapidly.
            </div> */}
            <div className="mt-3">
              <button
                type="button"
                className=" bg-black inline-flex h-10 select-none lg:text-base text-xs items-center justify-center w-56 text-white px-7 py-2 font-medium leading-5 outline-none rounded-full cursor-pointer"
                onClick={() => navigate(`/help-scale`)}
              >
                Connect With Fownd
              </button>
            </div>
          </div>
          <div className="bg-white/25 rounded-lg px-5 py-5 flex flex-col items-center mb-3">
            <div className="font-archivo font-bold lg:text-xl text-base text-crayola">
              Help us improve the platform !
            </div>
            <div className="mt-12">
              <img
                src="/assets/images/teams/card-3.svg"
                alt="fownd-team"
                className="lg:h-32 h-20 w-auto object-contain"
              />
            </div>
            <div className="bg-white rounded-lg px-3 py-5 w-full lg:mt-12 mt-3">
              <div className="border-b border-gray flex items-center space-x-4 pb-3">
                <img src="/assets/images/icons/discord.png" alt="discord" />
                <div className="font-dmSans font-bold text-base text-black">
                  Discord
                </div>
              </div>
              <div className="">
                <div className="flex items-center space-x-5 pb-2 mt-5">
                  {/* <StarIcon /> */}
                  <div className="font-dmSans font-normal text-xs text-black">
                    Securely communicate, Influence features and test the
                    platform
                  </div>
                </div>
              </div>

              <div className="mt-6 flex flex-col justify-center items-center">
                <button
                  className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full px-8 h-9"
                  onClick={() => window.open("https://discord.gg/VcjDkXAwAt")}
                >
                  Join
                </button>
                <div className="flex items-center">
                  <div>
                    <img
                      src="/assets/images/icons/light-bulb.svg"
                      alt="light-bulb"
                    />
                  </div>
                  <div
                    className="text-[#6C63FF] font-dmSans font-bold text-xs underline"
                    onClick={() =>
                      window.open("https://www.youtube.com/watch?v=TJ13BA3-NR4")
                    }
                  >
                    Learn about discord
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg px-3 py-5 w-full mt-4">
              <div className="border-b border-gray flex items-center space-x-4 pb-3">
                <img src="/assets/images/icons/facebook.png" alt="discord" />
                <div className="font-dmSans font-bold text-base text-black">
                  Facebook
                </div>
              </div>
              <div className="mt-6 text-center">
                <div className="font-dmSans font-normal text-xs text-black">
                  Support us, keep in touch and be in the know
                </div>
              </div>
              <div className="mt-6 flex justify-center items-center">
                <button
                  className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full px-8 h-9"
                  onClick={() =>
                    window.open("http://www.facebook.com/groups/fownd/")
                  }
                >
                  Join
                </button>
              </div>
            </div>
            {/* <div className="rounded-lg bg-white px-3 py-5 mb-4">
              <div className="flex items-center space-x-5 pb-4 border-b border-gray">
                
              </div>
              <div className="flex items-center justify-center space-x-5 pb-2 mt-5 mb-4">
                
              </div>
              <div className="mt-6">
                <button
                  className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full w-full h-9"
                >
                  Join
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Invest;
