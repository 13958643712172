import React, { useCallback, useEffect, useState } from 'react'
import ReactPlayer from "react-player";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import url from '../config';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import { Button } from '../component/ui';

const Demo = () => {

  let navigate = useNavigate();
  const [isVideoEnd, setIsVideoEnd] = useState(false)
  const [NONPTSurvey, setNONPTSurvey] = useState(false)
  const [showSkipButton, setShowSkipButton] = useState(false);
  const [videoLink, setVideoLink] = React.useState("");

  const handleProgress = (progress: any) => {
    const currentTime = progress.playedSeconds;
    if (currentTime >= 10) {
      setShowSkipButton(true);
    }
  };

  const fetchVideoData = useCallback(
    async () => {
      let requestURL
      let fowndData: any = localStorage.getItem("fownd");
      fowndData = (JSON.parse(fowndData))
      if (fowndData?.email) {
        requestURL = `${url}/demo/video-access?email=${fowndData?.email}`
      } else {
        requestURL = `${url}/demo/video-access`
      }
      await axios
        .get(requestURL)
        // .get(`${url}/intro/video-access`)
        .then((res) => {
          setVideoLink(res?.data?.accessUrlDemo)

        })
        .catch((err) => {
          toast.error("Something went Wrong");
        });
    },
    [setVideoLink],
  )

  useEffect(() => {
    const surveyType = localStorage.getItem("fownd-NONPT")
    const booleanValue = surveyType ? JSON.parse(surveyType) : false;
    if (booleanValue) {
      setNONPTSurvey(true);
    }
    const surveyNONPT = localStorage.getItem("fownd-NONPT")
    const booleanValueNONPT = surveyNONPT ? JSON.parse(surveyNONPT) : false;
    if (booleanValueNONPT) {
      localStorage.removeItem("fownd-NONPT")
    }
    const surveyPT = localStorage.getItem("fownd-PT")
    const booleanValuePT = surveyPT ? JSON.parse(surveyPT) : false;
    if (booleanValuePT) {
      localStorage.removeItem("fownd-PT")
    }
    fetchVideoData()
  }, [fetchVideoData])

  return (
    <div className="flex justify-center items-center">
      <div className={clsx(isVideoEnd ? "md:pt-56 pt-32" : "md:pt-56 pt-0",
        "md:h-auto xl:h-screen flex justify-center items-center ")}>
        <div>
          <div>
            {!isVideoEnd && (
              <div className="flex justify-center">
                <ReactPlayer
                  // url="https://www.youtube.com/watch?v=yjRHZEUamCc&ab_channel=LionsgateMovies"
                  // url="https://d9slkirznlji4.cloudfront.net/demo%20HLS/output.m3u8?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEO3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRjBEAiBb6n7gWBNcxps6OxGPxEVVJOf8r3Sx1NSoYfRjeEWXlgIgQPUGoRvXRHd47lSDaG%2FQiYBo592O%2FVcQ3jJdsV8oCPkq%2FQIIVhAAGgw4MjYxNTMyNzQ5MDIiDAqudvwClNKVQT7B0yraAiRGtyaYBKbsO%2FPZ%2Buq3WDL4Ofz%2B17oxr3C0K9x%2FGSPeH5sWEwEDp1vbgNCpmbW4AfoiIJyEtDHhoCpjt1VggDhMmXg52F4hY3d6SkU%2BzKGkXKFl9Ir07RrmAOD32hKFSuoQw0w1iX%2BikEmEqhZd9ZKYkcr7C28Q1%2FwXampHGbmtclMrA4qQptoQ6XxyCn2VLGS7H1th7TcsiEf3krs2cLEc4NIiYYDZzGX8Z96cazfndjBancTEsx7LmukhMIEKpW5gvolJ%2FFwxYXVfFwJYnvAOE3C5%2BaAlJL7NLpExtVd18FBfvLMF20uLZ7WzYIDQMHLn6ME3zx%2BAtckSGVdcDtGMPBG7lAS7Ji2tOecshd%2FS%2FrAq%2BF03r8ncahBAp0%2Bs9lwPgYC9djp1BQDFuxsHOaEbvjdxNLs89TGKP0UtviEUxSN%2F%2BT1ydG1lFFi0wgH%2BPw5ldGyPFzux0AgwgNDppAY6tAKXWPUD%2BG3lp%2FWr5224vUAIQoGpsb9bNF%2BxCnI%2FdAJcmENJPQJrtQ0n6e4e%2FD736zuF7oNUWR4azhrxy61Y7K8xn9yetqfFDGQaW3dx8teIUubjAc0EGGn61jhMhmZjXUk2PpLDfwxOaqmCpVEoqsC1R0MGs%2B2rhRBbHbrfBSIaAu2ifUArySbE32fgFArfJNhz7yK4JhUjqqmiDdpP3FRJWzjhA6Z80%2Fo0%2BmlpKseBr4M1S8qZIf97V681umQF67GDwYI3XSrXwzcb13HoEgnMSXBmUBp8S%2BVBtFyH3y7fFMCtryUWODEUTyZMTjuWJAteHDXbd91b1SW61FurT1O%2FHgI6FFSZIHlTR53rO3Ho457DRYLsyEaYbD4tbrSuKZZjLjWRek9ZAL1wkl1YOF1%2FsNhKiQ%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230627T050456Z&X-Amz-SignedHeaders=host&X-Amz-Expires=720&X-Amz-Credential=ASIA4AWUSRYLAR4FMK6F%2F20230627%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=5af44b1e8db9c8e7b55f1556297a6b384af11439b559895a77971defacce0e6a"
                  url={videoLink}
                  controls
                  playing={true}
                  volume={1}
                  width="90%"
                  height="100%"
                  onProgress={handleProgress}
                  onReady={() => console.log("ready now1")}
                  // onEnded={() => navigate("/survey-confirm")}
                  onEnded={() => setIsVideoEnd(true)}
                />
                {showSkipButton && (
                  <button
                    className="absolute bottom-[24.5rem] p-2 xl:bottom-0 xl:right-[95px] md:bottom-72 md:right-10 right-5 lg:absolute lg:bottom-48 lg:right-[193px] lg:p-3 bg-black text-white border-none cursor-pointer"
                    // onClick={() => navigate("/survey-confirm")}
                    onClick={() => setIsVideoEnd(true)}
                  >
                    Skip Video
                  </button>
                )}
              </div>
            )}

            {isVideoEnd && (
              <div className="relative">
                <img
                  className=" h-52 md:h-52 lg:h-60 xl:h-80 w-auto m-auto"
                  src="/assets/images/logo/wotermark.png"
                  alt="logo"
                />
                <div className="max-w-[300px] left-14 md:left-[7.5rem] lg:left-[7.5rem] font-inter font-bold text-5xl absolute lg:top-28 top-16 capitalize text-center">
                  what do you think?
                </div>
                <div className="font-dmSans font-medium text-xl text-center p-2 max-w-lg">
                  Take a 1 Minute Survey to be entered for a chance to win <span className="text-[#9747FF]">$100 Amazon digital gift card</span> in FOWND SURVEY AMAZON SWEAPSTAKE
                </div>
                <div className="flex justify-center my-5">
                  <img src="/assets/images/icons/gift-card.png" alt="fownd" />
                </div>
                <div className="p-2 flex flex-col justify-center mt-16 md:mt-28 lg:mt-3 space-y-3">
                  <button
                    className="p-4 inline-flex items-center text-white font-dmSans text-xl justify-center shadow-2xl bg-gray h-12 rounded-full border border-gray"
                    onClick={() => {
                      localStorage.setItem("fownd-PT", "true")
                      navigate("/disclaimer?survey=true")
                    }}
                  >
                    Survey For Physical Therapists
                  </button>
                  <button
                    className="p-4 inline-flex items-center text-gray font-dmSans text-xl justify-center shadow-2xl bg-white h-12 rounded-full border border-gray"
                    onClick={() => {
                      localStorage.setItem("fownd-NONPT", "true")
                      navigate("/disclaimer?non-pt-survey=true")
                    }}
                  >
                    General Platform Survey
                  </button>
                </div>
              </div>
            )}

            {/* {isVideoEnd && (
              <div className="relative">
                <img
                  className="h-52 md:h-52 lg:h-60 xl:h-80 w-auto m-auto"
                  src="/assets/images/logo/wotermark.png"
                  alt="logo"
                />
                <div
                  className="max-w-[300px] left-14 md:left-[7.5rem] lg:left-[7.5rem] font-inter font-bold text-5xl absolute lg:top-28 top-16 capitalize text-center"
                >
                  
                </div>
                
                <div className="flex justify-center lg:mt-3 mt-16">
                  <Button
                    
                    outlined={false}
                    
                    className="font-dmSans font-normal text-xl bg-gray"
                  >
                    Start Survey
                  </Button>
                </div>
                <div
                  className="flex justify-center font-dmSans text-xl font-medium underline mt-7 mb-5 cursor-pointer"
                  onClick={() => navigate("/success")}
                >
                  Skip Survey
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Demo