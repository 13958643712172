import { useContext } from "react"
import FormWrapper from "../FormWrapper/FormWrapper"
import { ItemsContext } from "../../../data/context"
import clsx from "clsx"
type Step1Data = {
  question?: any
}

type Step1FormProps = Step1Data & {
  updateFields: (fields: Partial<Step1Data>) => void
}

export function StepForm({
  question,
  updateFields,
}: Step1FormProps) {

  const { items, addItem } = useContext(ItemsContext);

  let defaultValues = items.find((obj) => obj.name === question?.name);
  return (
    <FormWrapper title="">
      <div className="font-dmSans font-medium text-xl text-[#231F20]">{question?.lable}</div>
      <div className="flex">
        <div></div>
        <label className="font-dmSans text-gray font-normal text-base">{question?.title}</label>
      </div>
      {!Array.isArray(question) && (
        <div className="ks-cboxtags lg:flex lg:flex-wrap lg:space-x-4">
          {question?.choices.map((option: any, index: number) => {
            return (
              <div key={index} className="mb-6">
                {question?.type === "checkbox" && (
                  <label htmlFor={option} className={`checkbox-label ${defaultValues?.key?.includes(option) ? 'checked' : ''}`}>
                    <input
                      id={option}
                      name={option}
                      value={option}
                      className={
                        clsx(defaultValues?.key?.includes(option)
                          ? "bg-gray text-white"
                          : "bg-white text-gray",
                          "rounded-full border-gray px-4 py-2 cursor-pointer checkbox-input"
                        )}
                      type="checkbox"
                      checked={defaultValues?.key?.includes(option)}
                      onChange={e => {
                        if (e.target.checked) {
                          defaultValues = { key: e.target.value, name: question?.name, type: question?.type }
                          addItem({ key: e.target.value, name: question?.name, type: question?.type })
                        } else {
                          defaultValues = { key: e.target.value, name: question?.name, type: question?.type, action: "remove" }
                          addItem({ key: e.target.value, name: question?.name, type: question?.type, action: "remove" })
                          console.log("remove checked", e.target.value)
                        }
                      }}
                    />
                    <span className="checkbox-icon">
                      {defaultValues?.key?.includes(option) ? <i className="fas fa-check"></i> : <i className="fas fa-plus"></i>}
                    </span>
                    {option}
                  </label>
                )}
                {question?.type === "radiogroup" && (
                  <label htmlFor={option} className={
                    clsx(defaultValues?.key === option
                      ? "bg-gray text-white"
                      : "bg-white text-gray",
                      "rounded-full border-gray border-[0.5px] px-4 py-2 cursor-pointer"
                    )}>
                    <input
                      id={option}
                      name={question}
                      type="radio"
                      required
                      value={option}
                      checked={defaultValues?.key === option}
                      hidden
                      className="mr-2"
                      onChange={e => {
                        defaultValues = { key: e.target.value, name: question?.name, type: question?.type }
                        addItem({ key: e.target.value, name: question?.name, type: question?.type })
                      }}
                    />
                    {option}
                  </label>
                )}
              </div>
            );
          })}
          {question?.type === "textarea" && (
            <textarea
              className="w-full border-[0.5px] text-black focus:outline-none focus:border-[0.5px] border-gray focus:border-gray p-2 font-semibold rounded-md"
              rows={4}
              onChange={e => {
                defaultValues = { key: e.target.value, name: question?.name, type: question?.type }
                addItem({ key: e.target.value, name: question?.name, type: question?.type })
              }}
            />
          )}
          {question?.type === "text" && (
            <input
              type="email"
              required
              className="w-full border-[0.5px] text-black focus:outline-none focus:border-[0.5px] border-gray focus:border-gray p-2 font-semibold rounded-md"
              onChange={e => {
                defaultValues = { key: e.target.value, name: question?.name, type: question?.type }
                addItem({ key: e.target.value, name: question?.name, type: question?.type })
              }}
            />
          )}
        </div>
      )}

      {Array.isArray(question) && (
        <div className="ks-cboxtags">
          {question.map((subQuestion: any, index: number) => {
            return (
              <div key={index} className="mb-6">
                <label className="font-dmSans text-gray font-normal text-base">{subQuestion?.title}</label>
                {subQuestion?.choices?.length > 0 && subQuestion?.choices.map((option: any, index: number) => {
                  return (
                    <div key={index} className="mb-6 mt-2">
                      {subQuestion?.type === "checkbox" && (
                        <label htmlFor={option} className={`checkbox-label ${defaultValues?.key?.includes(option) ? 'checked' : ''}`}>
                          <input
                            id={option}
                            name={option}
                            value={option}
                            className={
                              clsx(defaultValues?.key?.includes(option)
                                ? "bg-gray text-white"
                                : "bg-white text-gray",
                                "rounded-full border-gray px-4 py-2 cursor-pointer checkbox-input"
                              )}
                            type="checkbox"
                            checked={defaultValues?.key?.includes(option)}
                            onChange={e => {
                              if (e.target.checked) {
                                defaultValues = { key: e.target.value, name: subQuestion?.name, type: subQuestion?.type }
                                addItem({ key: e.target.value, name: subQuestion?.name, type: subQuestion?.type })
                              } else {
                                defaultValues = { key: e.target.value, name: subQuestion?.name, type: subQuestion?.type, action: "remove" }
                                addItem({ key: e.target.value, name: subQuestion?.name, type: subQuestion?.type, action: "remove" })
                                console.log("remove checked", e.target.value)
                              }
                            }}
                          />
                          <span className="checkbox-icon">
                            {defaultValues?.key?.includes(option) ? <i className="fas fa-check"></i> : <i className="fas fa-plus"></i>}
                          </span>
                          {option}
                        </label>
                      )}
                      {subQuestion?.type === "radiogroup" && (
                        <label htmlFor={option} className={
                          clsx(defaultValues?.key === option
                            ? "bg-gray text-white"
                            : "bg-white text-gray",
                            "rounded-full border-gray border-[0.5px] px-4 py-2 cursor-pointer"
                          )}>
                          <input
                            id={option}
                            // name={`age_${option}`}
                            type="radio"
                            required
                            value={option}
                            checked={defaultValues?.key === option}
                            hidden
                            className="mr-2"
                            onChange={e => {
                              defaultValues = { key: e.target.value, name: subQuestion?.name, type: subQuestion?.type }
                              addItem({ key: e.target.value, name: subQuestion?.name, type: subQuestion?.type })
                            }}
                          />
                          {option}
                        </label>
                      )}
                    </div>
                  );
                })}
                {subQuestion?.type === "textarea" && (
                  <textarea
                    className="w-full border-[0.5px] text-black focus:outline-none focus:border-[0.5px] border-gray focus:border-gray p-2 font-semibold rounded-md"
                    rows={4}
                    onChange={e => {
                      defaultValues = { key: e.target.value, name: subQuestion?.name, type: subQuestion?.type }
                      addItem({ key: e.target.value, name: subQuestion?.name, type: subQuestion?.type })
                    }}
                  />
                )}
                {subQuestion?.type === "text" && (
                  <input
                    type="text"
                    required
                    className="w-full border-[0.5px] text-black focus:outline-none focus:border-[0.5px] border-gray focus:border-gray p-2 font-semibold rounded-md"
                    onChange={e => {
                      defaultValues = { key: e.target.value, name: subQuestion?.name, type: subQuestion?.type }
                      addItem({ key: e.target.value, name: subQuestion?.name, type: subQuestion?.type })
                    }}
                  />
                )}
                {subQuestion?.type === "email" && (
                  <input
                    type="email"
                    required
                    className="w-full border-[0.5px] text-black focus:outline-none focus:border-[0.5px] border-gray focus:border-gray p-2 font-semibold rounded-md"
                    onChange={e => {
                      defaultValues = { key: e.target.value, name: subQuestion?.name, type: subQuestion?.type }
                      addItem({ key: e.target.value, name: subQuestion?.name, type: subQuestion?.type })
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      )}

      {/* <input
        autoFocus
        type={question?.type}
        value={question}
        onChange={e => updateFields({ question: e.target.value })}
      /> */}
    </FormWrapper>
  )
}