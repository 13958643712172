import clsx from 'clsx'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal } from '../component/ui/Modal';
import { Button, JoinUs } from '../component/ui';
import { ItemsContext } from '../data/context';

const DemoSuccess = () => {

    let navigate = useNavigate();

    const { ptQuestion, setPTQuestion } = useContext(ItemsContext);

    const [takeSurvey, setTakeSurvey] = useState(false);
    const [NONPTSurvey, setNONPTSurvey] = useState(false);
    const [noSurvey, setNoSurvey] = useState(false);
    const [cancelSurvey, setCancelSurvey] = useState(false);

    useEffect(() => {
        const surveyType = localStorage.getItem("fownd-NONPT")
        const booleanValue = surveyType ? JSON.parse(surveyType) : false;
        if (booleanValue) {
            setNONPTSurvey(true);
        }
    }, [])

    const onCloseSurvey = () => {
        setTakeSurvey(false);
        setNoSurvey(false);
        setCancelSurvey(true);
    }

    const onNoSurveyClose = () => {
        setNoSurvey(false);
        setTakeSurvey(false);
        setCancelSurvey(true)
    }

    const onBackToPool = () => {
        setPTQuestion("Yes");
        setTakeSurvey(true);
        setNoSurvey(false);
        setCancelSurvey(false);
    }

    return (
        <div className="h-screen flex justify-center items-center">
            <div className={clsx("md:pt-56 pt-0 md:h-auto xl:h-screen flex justify-center items-center ")}>
                <div>
                    <div>
                        <div className="relative">
                            <img
                                className="h-52 md:h-52 lg:h-60 xl:h-80 w-auto m-auto"
                                src="/assets/images/logo/wotermark.png"
                                alt="logo"
                            />
                            <div
                                className="max-w-[300px] left-14 md:left-[7.5rem] lg:left-[7.5rem] font-inter font-bold text-5xl absolute lg:top-28 top-16 capitalize text-center"
                            >
                                what do you think?
                            </div>
                            <div className="font-dmSans font-medium text-xl text-center p-2">
                                Would you Practice on a platform like Fownd?
                            </div>
                            <div className="flex justify-center space-x-2 lg:mt-3 mt-16">
                                <button
                                    type="button"
                                    className={clsx(ptQuestion === "Yes" ?
                                        "bg-gray text-white" :
                                        "bg-white text-gray",
                                        "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-2xl"
                                    )}
                                    onClick={() => { setPTQuestion("Yes"); setTakeSurvey(true); }}
                                >
                                    Yes
                                </button>
                                <button
                                    type="button"
                                    className={clsx(ptQuestion === "No" ?
                                        "bg-gray text-white" :
                                        "bg-white text-gray",
                                        "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-2xl"
                                    )}
                                    onClick={() => { setPTQuestion("No"); setNoSurvey(true); }}
                                >
                                    No
                                </button>
                                <button
                                    type="button"
                                    className={clsx(ptQuestion === "Maybe" ?
                                        "bg-gray text-white" :
                                        "bg-white text-gray",
                                        "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-2xl"
                                    )}
                                    onClick={() => { setPTQuestion("Maybe"); setTakeSurvey(true); }}
                                >
                                    Maybe
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="" show={takeSurvey} onClose={onCloseSurvey}>
                <div className="px-10 mb-3 flex flex-col items-center">
                    <img src="/assets/images/survey/survey-confirm.png" alt="fownd" />
                    <div className="font-dmSans font-medium text-xl text-center mt-5">
                        Take a one-minute poll and get free early access to FOWND
                    </div>
                    <Button
                        loading={false}
                        outlined={false}
                        width={40}
                        className="font-dmSans font-normal text-xl bg-gray mt-4"
                        onClick={() => {
                            if (NONPTSurvey) {
                                navigate("/non-pt-survey")
                            } else {
                                navigate("/survey")
                            }
                        }}
                    >
                        Participate
                    </Button>
                    <div className="font-dmSans font-medium text-base text-gray text-center my-7">
                        You will also see PT poll results on completion
                    </div>
                </div>
            </Modal>
            <Modal title="" show={noSurvey} onClose={onNoSurveyClose}>
                <div className="px-10 mb-3 flex flex-col items-center">
                    <div className="font-dmSans font-medium text-xl text-center mt-5">
                        Are you sure you don’t want to continue and no longer participate?
                    </div>
                    <div className="flex justify-center space-x-4 lg:mt-3 mt-16">
                        <button
                            type="button"
                            className={clsx(takeSurvey ?
                                "bg-gray text-white" :
                                "bg-white text-gray",
                                "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-base"
                            )}
                            onClick={onNoSurveyClose}
                        >
                            No, Cancel
                        </button>
                        <button
                            type="button"
                            className={clsx(takeSurvey ?
                                "bg-gray text-white" :
                                "bg-white text-gray",
                                "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-base"
                            )}
                            onClick={() => {
                                if (NONPTSurvey) {
                                    navigate("/non-pt-survey")
                                } else {
                                    navigate("/survey")
                                }
                            }}
                        >
                            Yes, Continue
                        </button>
                    </div>
                </div>
            </Modal>
            <Modal title="" show={cancelSurvey} onClose={() => navigate("/")}>
                <div
                    className="px-5 font-dmSans font-bold text-base text-gray cursor-pointer flex items-center"
                    onClick={onBackToPool}
                >
                    <span className="material-icons-round">chevron_left</span> <span>Back to poll</span>
                </div>
                <div className="px-10 mb-3 flex flex-col items-center">
                    <div className="font-dmSans font-bold text-xl text-center mt-5">
                        Thank you!
                    </div>
                    <div className="font-dmSans font-medium text-base text-center mt-5">
                        Please reconsider improving the platform with a quick 1 min poll
                    </div>
                    <div className="mt-5">
                        <div className="font-dmSans font-medium text-base text-center mb-2">Join our Social</div>
                        <JoinUs />
                    </div>
                    <div
                        className="font-dmSans font-bold text-base mt-5 underline text-gray"
                        onClick={() => navigate("/success")}
                    >
                        View poll result
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DemoSuccess