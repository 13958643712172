import React from "react";

const HamburgIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="currentColor"
      {...props}
    >
      <path
        d="M4.75 28.5H33.25V25.3333H4.75V28.5ZM4.75 20.5833H33.25V17.4167H4.75V20.5833ZM4.75 9.5V12.6667H33.25V9.5H4.75Z"
        fill="currentColor"
        {...props}
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default HamburgIcon;
