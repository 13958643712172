import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ItemsContext } from "../../data/context";
import { HamburgIconLight, SiteLogo } from "../../component/Icons";
import { Sidebar } from "../../component/ui/Modal";
import clsx from "clsx";

const Header = () => {
  let navigate = useNavigate();
  const location = useLocation();

  const { isSideBarOpen, setIsSideBarOpen } = useContext(ItemsContext);
  const [scrolled, setScrolled] = useState(false);

  const openModal = () => {
    setIsSideBarOpen(true);
  };

  const closeModal = () => {
    setIsSideBarOpen(false);
  };

  const onClickLogo = () => {
    if (location?.pathname === "/") {
      // Already on landing page, scroll to top
      window.scrollTo(0, 0);
    } else {
      navigate(`/`);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 66) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={clsx(
          scrolled ? "bg-gray" : "bg-gray",
          "px-4 md:px-14 w-full py-5 fixed top-0 z-10 flex justify-between"
        )}
      >
        <div>
          <SiteLogo
            onClick={() => onClickLogo()}
            className="text-white cursor-pointer w-8 sm:w-12 h-full"
          />
        </div>
        <div className="lg:flex hidden items-end space-x-7 space-y-3 lg:space-y-0 lg:flex-row lg:justify-center lg:items-center">
          <div className="flex items-center justify-center space-x-3">
            {/* <div>
            <button
              type="button"
              className="bg-white inline-flex space-x-2 select-none items-center h-10 justify-center text-black text-base px-7 font-black leading-5 outline-none rounded-full cursor-pointer font-productSans"
              onClick={() => navigate(`/demo`)}
            >
              <img src="/assets/images/share/play.svg" alt="fownd" />
              <div>Platform Demo</div>
            </button>
          </div> */}
            {/* <div>
              <button
                type="button"
                className="inline-flex space-x-2 select-none items-center h-10 justify-center text-black text-base px-7 font-black leading-5 outline-none rounded-full cursor-pointer font-productSans"
                onClick={() => navigate(`/business-model`)}
              >
                <img
                  src="/assets/images/share/polygon.svg"
                  // src="/assets/images/share/Polygon.png"
                  alt="fownd"
                  className="object-contain h-6 w-6"
                />
                <div className="gradient-title">Founder Intro</div>
              </button>
            </div> */}
          </div>
          <HamburgIconLight
            className="cursor-pointer w-6 sm:w-[38px] h-6 sm:h-[38px]"
            onClick={openModal}
          />
        </div>
        <div className="lg:hidden flex space-x-3 items-center justify-center">
          {/* <div className="bg-white px-4 py-2 rounded-full flex items-center space-x-1">
            <div className="text-[8px] font-black font-productSans">
              Platform Demo
            </div>
            <img
              src="/assets/images/share/airplay.png"
              alt="fownd-intro"
              onClick={() => navigate(`/demo`)}
            />
          </div> */}
          {/* <div className="px-4 py-2 rounded-full flex items-center space-x-1">
            <img
              src="/assets/images/share/polygon.svg"
              alt="fownd-demo"
              onClick={() => navigate(`/business-model`)}
            />
            <div className="gradient-title">Founder Intro</div>
          </div> */}
          <HamburgIconLight
            className="cursor-pointer w-6 sm:w-[38px] h-6 sm:h-[38px]"
            onClick={openModal}
          />
        </div>
        <Sidebar isOpen={isSideBarOpen} closeModal={closeModal} />
      </div>
    </div>
  );
};

export default Header;
