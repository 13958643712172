import React from "react";
import Chart from "chart.js/auto";

const useChart = (nodeRef:any, options:any) => {
  React.useEffect(() => {
    const chart = Chart.getChart(nodeRef.current.id);
    if (chart) {
      chart.destroy();
    }
    new Chart(nodeRef.current, options);

    const resizeChart = () => {
      if (chart) {
        chart.resize();
      }
    };
    console.log("render");
  }, [nodeRef, options]);

  return {};
};

export default useChart;