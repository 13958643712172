import React, { useEffect } from "react";

import clsx from "clsx";
import axios from "axios";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import url from "../config";
import { Modal } from "../component/ui/Modal";
import { Button, InputText } from "../component/ui";

const Scale = () => {
    let navigate = useNavigate();
    const [isModelOepn, setIsModelOepn] = React.useState(false);
    const [isModelOepnThankyou, setIsModelOepnThankyou] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [accessCode, setaccessCode] = React.useState("");
    const [fName, setFname] = React.useState("");
    const [lName, setLname] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [state, setState] = React.useState("");
    const [companyName, setCompanyName] = React.useState("");
    const [ipAddress, setIPAddress] = React.useState('')
    const [requestAccess, setRequestAccess] = React.useState(true);
    const [learnAbout, setLearnAbout] = React.useState("");
    const [connect, setConnect] = React.useState("");
  
    const onSubmit = () => {
      setLoading(true)
      const body = {
        lname: lName.toLowerCase(),
        fname: fName.toLowerCase(),
        email: email.toLowerCase(),
        licenseNumber: "licenseNumber",
        state: state.toLowerCase(),
        companyName: companyName,
        requestAccess: requestAccess,
        ipAddress: ipAddress,
        learnAbout: learnAbout,
        connect: connect
      };
      axios
        .post(`${url}/signup`, body)
        .then((res) => {
          setLoading(false)
          localStorage.setItem("fownd", JSON.stringify({ email: email.toLowerCase(), name: `${fName}` }))
          if (res?.data?.requestAccess === true) {
            setIsModelOepnThankyou(true);
          } else if (res?.data?.isVerified) {
            navigate(`/business-model`);
          } else {
            setIsModelOepn(true);
            toast.success("Your Access Code has been sent to your Email.");
          }
        })
        .catch((err) => {
          console.log(err, "err");
          setLoading(false)
          toast.error("Something went Wrong");
        });
    };
  
    const onCheckAccessCode = () => {
      setLoading(true)
      const body = {
        code: accessCode,
      };
      axios
        .post(`${url}/validate-code`, body)
        .then((res) => {
          if (res?.data?.requestAccess) {
            setLoading(false)
            setIsModelOepnThankyou(true)
            setIsModelOepn(false)
          } else {
            setLoading(false)
            navigate(`/business-model`);
          }
  
        })
        .catch((err) => {
          console.log(err, "err");
          setLoading(false)
          toast.error("Access code must be valid.");
        });
    };
  
    useEffect(() => {
      const fetchIPAddress = async () => {
        try {
          const response = await axios.get('https://api.ipify.org?format=json');
          setIPAddress(response.data.ip);
        } catch (error) {
          console.log('Error fetching IP address:', error);
        }
      };
      fetchIPAddress();
    }, []);
  
    return (
      <>
        <div className="pt-32 md:h-auto flex justify-center items-center px-6 lg:h-screen">
          <div>
            {!requestAccess && (
              <div className="text-center space-y-2 mb-5">
                <h3 className="font-inter text-3xl font-bold uppercase">
                  WE HAVE DESIGNED A PREVIEW OF OUR PLATFORM FOR PHYSICAL THERAPISTS
                </h3>
                <p className="font-dmSans font-normal text-xl">
                  Some basic information below will give you access.{" "}
                </p>
                <p className="font-dmSans text-gray max-w-sm m-auto font-normal text-xl">
                  Fownd will not sell or monetize any of the information you
                  provide.
                </p>
              </div>
            )}
            {requestAccess && (
              <div className="text-center space-y-2 mb-5">
                <h3 className="font-inter text-3xl font-bold uppercase">
                  Help us build faster
                </h3>
                <p className="font-dmSans font-normal text-xl">
                  Leave us a note if you believe in our mission!
                </p>
              </div>
            )}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mb-5">
              <InputText
                title={"First Name"}
                placeholder={"First Name"}
                onChange={(e) => setFname(e)}
              />
              <InputText
                title={"Last Name"}
                placeholder={"Last Name"}
                onChange={(e) => setLname(e)}
              />
              <InputText
                title={"Email"}
                placeholder={"Email"}
                onChange={(e) => setEmail(e)}
              />
              {requestAccess && (<InputText
                title={"Company Name"}
                placeholder={"Company Name"}
                onChange={(e) => setCompanyName(e)}
              />
              )}
  
            </div>
            <div>
            {requestAccess && (
              <div className="mb-5">
                <div className="font-bold font-dmSans text-base text-gray mb-5">
                  How did you learn about Fownd?
                </div>
                <div className="lg:flex lg:space-x-4">
                  <div className="mb-6 lg:mb-4">
                    <input
                      type="radio"
                      value="Investor"
                      id="investor"
                      name="learn-about"
                      hidden
                      onChange={(e) => setLearnAbout(e.target.value)}
                    />
                    <label
                      htmlFor="investor"
                      className={
                        clsx(learnAbout === "Investor"
                          ? "bg-gray text-white"
                          : "bg-white text-gray",
                          "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-base"
                        )}>
                      Investor
                    </label>
                  </div>
                  <div className="mb-6 lg:mb-4">
                    <input
                      type="radio"
                      value="Friend"
                      name="learn-about"
                      hidden
                      id="friend"
                      onChange={(e) => setLearnAbout(e.target.value)} />
                    <label
                      htmlFor="friend"
                      className={
                        clsx(learnAbout === "Friend"
                          ? "bg-gray text-white"
                          : "bg-white text-gray",
                          "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-base"
                        )}>
                      Friend
                    </label>
                  </div>
                  <div className="mb-6 lg:mb-4">
                    <input
                      type="radio"
                      value="Medical Office/ Professional"
                      hidden
                      id="option3"
                      name="learn-about"
                      onChange={(e) => setLearnAbout(e.target.value)}
                    />
                    <label
                      htmlFor="option3"
                      className={
                        clsx(learnAbout === "Medical Office/ Professional"
                          ? "bg-gray text-white"
                          : "bg-white text-gray",
                          "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-base"
                        )}>
                      Medical Office/ Professional
                    </label>
                  </div>
                </div>
              </div>
            )}
            {requestAccess && (
              <div>
                <div className="font-bold font-dmSans text-base text-gray mb-5">
                  Would you like to connect with the Fownd team?
                </div>
                <div className="lg:flex lg:space-x-4">
                  <div className="mb-6 lg:mb-4">
                    <input
                      type="radio"
                      value="Yes"
                      id="yes"
                      name="connect"
                      hidden
                      onChange={(e) => setConnect(e.target.value)}
                    />
                    <label
                      htmlFor="yes"
                      className={
                        clsx(connect === "Yes"
                          ? "bg-gray text-white"
                          : "bg-white text-gray",
                          "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-base"
                        )}>
                      Yes
                    </label>
                  </div>
                  <div className="mb-6 lg:mb-4">
                    <input
                      type="radio"
                      value="Just looking"
                      name="connect"
                      hidden
                      id="just-asking"
                      onChange={(e) => setConnect(e.target.value)} />
                    <label
                      htmlFor="just-asking"
                      className={
                        clsx(connect === "Just looking"
                          ? "bg-gray text-white"
                          : "bg-white text-gray",
                          "rounded-full border-[0.5px] border-gray px-4 py-2 cursor-pointer font-dmSans text-base"
                        )}>
                      Just looking
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
            <div className="flex justify-center mb-5">
              <Button
                outlined
                loading={loading}
                onClick={() => onSubmit()}
                className="font-archivo font-thin"
              >
                {requestAccess ? "Connect With Fownd" : "Confirm"}
              </Button>
            </div>
            {!requestAccess && (
              <div
                className="flex justify-center mb-5 font-dmSans font-medium underline"
                onClick={() => setRequestAccess(true)}
              >
                Access for Non-PT's
              </div>
            )}
          </div>
        </div>
  
        <Modal title="" show={isModelOepn} onClose={() => setIsModelOepn(false)}>
          <div className="px-10">
            <h3 className="font-dmSans max-w-xl text-center m-auto lg:text-xl text-base mb-5">
              Your Access Code has been sent to your email. Look for an email from <span className="text-gray">no-reply@fownd.care</span> in case it landed in junk/spam/
            </h3>
  
            <div className="max-w-xs m-auto">
              <InputText
                title={"Access Code"}
                placeholder={"eg: Access Code"}
                value={accessCode}
                onChange={(e: string) => setaccessCode(e)}
              />
              {/* <InputOtp
                title={"Enter access code"}
                value={accessCode}
                onChange={(e: string) => setaccessCode(e)}
              /> */}
            </div>
            <div className="flex justify-center mb-10 mt-20">
              <Button
                outlined
                loading={loading}
                onClick={() => onCheckAccessCode()}
                className="font-archivo font-thin"
              >
                Login
              </Button>
            </div>
          </div>
        </Modal>
  
        <Modal title="" show={isModelOepnThankyou} onClose={() => navigate("/")}>
          <div className="px-10 mb-3">
            <h3 className="font-inter font-bold max-w-xl text-center m-auto text-xl mb-5 capitalize">
            thank you, we have received your response
            </h3>
            <div className="font-dmSans font-normal text-xl text-center"> Check your email to gain access to the demo!</div>
          </div>
        </Modal>
      </>
    );
}

export default Scale