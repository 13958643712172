import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import React, { useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ItemsContext } from "./data/context";
import clsx from "clsx";
import ScrollToTop from "./Hooks/useNavigateToTop";
import Hotjar from "@hotjar/browser";

function App() {
  const { isPolicyModel, setIsPolicyModelOpen } = useContext(ItemsContext);
  const [showError, setShowError] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const handleScreenClick = () => {
    setShowAnimation(true);
    setTimeout(() => setShowAnimation(false), 1000); // Set animation duration (1 second in this case)
  };

  useEffect(() => {
    const siteId = 3714868;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);

    const policy = localStorage.getItem("fownd-policy");
    console.log(policy);
    // if (policy !== "false") {
    //   localStorage.setItem("fownd-policy", "true")
    // }
    console.log(policy);
    if (policy === "true") {
      setIsPolicyModelOpen(true);
    }

    if (isPolicyModel) {
      document.addEventListener("click", handleScreenClick);
    }

    return () => {
      document.removeEventListener("click", handleScreenClick);
    };
  }, [setIsPolicyModelOpen, isPolicyModel]);

  return (
    <>
      {isPolicyModel && (
        <Transition appear show={isPolicyModel} as={React.Fragment}>
          <Dialog
            as="div"
            className="relative"
            onClose={() => setShowError(true)}
            static
          >
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed bottom-0 left-0 right-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-7xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <div className="lg:mt-2 lg:h-full hidden lg:block">
                      {showError && (
                        <div className="text-[#FF0000] font-dmSans font-semibold">
                          {" "}
                          Please Accept Policy!!
                        </div>
                      )}
                      <div className="lg:font-archivo lg:text-xl lg:font-normal lg:mb-3">
                        We use <span className="lg:text-gray">cookies</span> to
                        improve your experience
                      </div>
                      <div className="lg:grid lg:grid-flow-col lg:gap-3">
                        <div className="lg:max-w-sm">
                          <img
                            src="/assets/images/icons/cookies.png"
                            alt="fownd-policy"
                          />
                        </div>
                        <div className="lg:font-archivo lg:font-normal lg:text-base lg:text-gray">
                          <p>
                            Cookies enhance your experience on Fownd’s websites.
                            They help us show you more relevant content. Some
                            are necessary for site functionality, security,
                            network management, so they are always active
                          </p>
                          <p>
                            By clicking “Accept all”, you are agreeing to the
                            placement and use of cookies as described in our
                            Cookie Policy. “Required only” means only Necessary
                            and Statustical analytics cookies are active, where
                            applicable
                          </p>
                        </div>
                        <div className="lg:pt-10">
                          <div className="lg:flex lg:space-x-2 lg:justify-end lg:items-center lg:mb-5 lg:mt-3">
                            <button
                              type="button"
                              className={clsx(
                                showAnimation && "under-pressure",
                                "lg:bg-gray lg:inline-flex lg:h-10 lg:select-none lg:items-center lg:justify-center lg:w-40 lg:text-white lg:px-7 lg:font-medium lg:leading-5 lg:outline-none lg:rounded-full lg:cursor-pointer"
                              )}
                              onClick={() => {
                                localStorage.removeItem("fownd-policy");
                                localStorage.setItem("fownd-policy", "false");
                                setIsPolicyModelOpen(false);
                              }}
                            >
                              Required Only
                            </button>
                            <button
                              type="button"
                              className={clsx(
                                showAnimation && "under-pressure",
                                " lg:bg-gray lg:inline-flex lg:h-10 lg:select-none lg:items-center lg:justify-center lg:w-40 lg:text-white lg:px-7 lg:font-medium lg:leading-5 lg:outline-none lg:rounded-full lg:cursor-pointer"
                              )}
                              onClick={() => {
                                localStorage.removeItem("fownd-policy");
                                localStorage.setItem("fownd-policy", "false");
                                setIsPolicyModelOpen(false);
                              }}
                            >
                              Accept All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 h-full block lg:hidden">
                      {showError && (
                        <div className="text-[#FF0000] font-dmSans font-semibold">
                          {" "}
                          Please Accept Policy!!
                        </div>
                      )}
                      <div className="flex items-center space-x-2">
                        <div className="max-w-sm">
                          <img
                            src="/assets/images/icons/cookies.png"
                            className="h-10 w-10 object-fill"
                            alt="fownd-policy"
                          />
                        </div>
                        <div className="font-archivo text-text-[10px] lg:text-xl font-normal mb-3">
                          We use <span className="text-gray">cookies</span> to
                          improve your experience
                        </div>
                      </div>
                      <div className="lg:grid lg:grid-flow-col lg:gap-3">
                        <div className="font-archivo font-normal text-[10px] lg:text-base text-gray">
                          <p>
                            Cookies enhance your experience on Fownd’s websites.
                            They help us show you more relevant content. Some
                            are necessary for site functionality, security,
                            network management, so they are always active
                          </p>
                          <p>
                            By clicking “Accept all”, you are agreeing to the
                            placement and use of cookies as described in our
                            Cookie Policy. “Required only” means only Necessary
                            and Statustical analytics cookies are active, where
                            applicable
                          </p>
                        </div>
                        <div>
                          <div className="flex space-x-2 justify-end items-center mt-2">
                            <button
                              type="button"
                              className={clsx(
                                showAnimation && "under-pressure",
                                " bg-gray inline-flex h-10 select-none items-center justify-center w-40 text-white text-xs px-7 font-medium leading-5 outline-none rounded-full cursor-pointer"
                              )}
                              onClick={() => {
                                localStorage.removeItem("fownd-policy");
                                localStorage.setItem("fownd-policy", "false");
                                setIsPolicyModelOpen(false);
                              }}
                            >
                              Required Only
                            </button>
                            <button
                              type="button"
                              className={clsx(
                                showAnimation && "under-pressure",
                                " bg-gray inline-flex h-10 select-none items-center justify-center w-40 text-white text-xs px-7 font-medium leading-5 outline-none rounded-full cursor-pointer"
                              )}
                              onClick={() => {
                                localStorage.removeItem("fownd-policy");
                                localStorage.setItem("fownd-policy", "false");
                                setIsPolicyModelOpen(false);
                              }}
                            >
                              Accept All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      <Router>
        <ScrollToTop />
        <AppRoutes />
        <Toaster position="top-right" />
      </Router>
    </>
  );
}

export default App;
