import React, { useCallback, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Button } from "../component/ui";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import url from "../config";
import { toast } from "react-hot-toast";
import clsx from "clsx";

const BusinessModel = () => {
  let navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [isVideoEnd, setIsVideoEnd] = useState(false)
  const [loading, setLoading] = React.useState(false);
  const [videoLink, setVideoLink] = React.useState("");
  const [showSkipButton, setShowSkipButton] = useState(false);

  const handleProgress = (progress: any) => {
    const currentTime = progress.playedSeconds;
    if (currentTime >= 10) {
      setShowSkipButton(true);
    }
  };

  const fetchVideoData = useCallback(
    async () => {
      let requestURL
      let fowndData: any = localStorage.getItem("fownd");
      fowndData = (JSON.parse(fowndData))
      if (fowndData?.email) {
        requestURL = `${url}/intro/video-access?email=${fowndData?.email}`
      } else {
        requestURL = `${url}/intro/video-access`
      }
      await axios
        .get(requestURL)
        .then((res) => {
          setVideoLink(res?.data?.accessUrl)
        })
        .catch((err) => {
          toast.error("Something went Wrong");
        });
    },
    [setVideoLink],
  )

  useEffect(() => {
    if (searchParams.get("NONPT")) {
      localStorage.setItem("fownd-NONPT", "true")
    }
    if (searchParams.get("email")) {
      localStorage.removeItem("fownd")
      localStorage.setItem("fownd", JSON.stringify({ email: searchParams.get("email") }))
    }
    fetchVideoData()
  }, [searchParams, fetchVideoData])

  return (
    <div className="h-screen flex justify-center">
      <div className={clsx(isVideoEnd ? "md:h-auto xl:h-screen flex justify-center items-center" : "md:h-auto xl:h-screen flex justify-center items-center md:pt-56")}>
        <div>
          <div>
            {!isVideoEnd && (
              <div>
                <ReactPlayer
                  // url="https://www.youtube.com/watch?v=yjRHZEUamCc&ab_channel=LionsgateMovies"
                  // url="https://d9slkirznlji4.cloudfront.net/Intro+HLS/output.m3u8"
                  url={videoLink}
                  playing={true}
                  volume={1}
                  controls={true}
                  width="100%"
                  height="100%"
                  onProgress={handleProgress}
                  onReady={() => console.log("ready now")}
                  onEnded={() => navigate('/')}
                />
                {/* {showSkipButton && (
                  <button
                    className="absolute bottom-[21.5rem] p-2 xl:bottom-48 xl:right-[325px] md:bottom-80 md:right-0 right-0 lg:absolute lg:bottom-48 lg:right-0 lg:p-3 bg-black text-white border-none cursor-pointer"
                    onClick={() => setIsVideoEnd(true)}
                  >
                    Skip Video
                  </button>
                )} */}
              </div>
            )}
            {/* {isVideoEnd && (
              <div className="relative">
                <img
                  className=" h-52 md:h-52 lg:h-60 xl:h-80 w-auto m-auto"
                  src="/assets/images/logo/wotermark.png"
                  alt="logo"
                />
                <div
                  className="font-fjalla font-normal text-5xl text-limed-spruce absolute xl:top-28 top-0 md:top-16 capitalize text-center"
                >
                  Lets Take A Look at the Platform in Action
                </div>
                <div className="flex justify-center lg:mt-3 mt-16 md:mt-28">
                  <Button
                    loading={loading}
                    outlined={false}
                    onClick={() => {
                      navigate(`/demo`);
                    }}
                    className="font-dmSans font-normal text-xl bg-gray"
                  >
                    Demo Fownd
                  </Button>
                </div>
                <div className="font-dmSans font-bold text-xl text-gray mt-6">
                  Amazon Gift Card Raffle at the end
                </div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessModel;
