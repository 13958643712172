import React from "react";

import clsx from "clsx";

import { XIcon } from "../../Icons";
import { Dialog, Transition } from "@headlessui/react";

export interface ModelProps {
  show?: boolean;
  onClose?: any;
  children?: any;
  title?: string | null;
  showCancel?: boolean;
  maxWidth?: string | number;
}

const Modal: React.FC<ModelProps> = (props) => {
  const { show, onClose, title, children, showCancel = true, maxWidth } = props;

  return (
    <Transition appear show={show} as={React.Fragment}>
      <Dialog as="div" className="relative z-50 rounded-lg" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  `max-w-2xl transform  bg-white rounded-lg align-middle font-gt-eesti shadow-xl transition-all`
                )}
              >
                {title !== undefined ||
                  (showCancel && (
                    <div className="relative z-50">
                      <button
                        className="inline-flex items-center justify-center shadow-2xl bg-white h-7 w-7 rounded-full border border-gray absolute right-2 top-2"
                        onClick={onClose}
                      >
                        <XIcon className="h-3 w-3 text-black" />
                      </button>
                    </div>
                  ))}
                {title !== undefined && (
                  <div
                    className={clsx(
                      showCancel
                        ? "flex justify-between items-center"
                        : "text-start",
                      "p-4 py-6 bg-blue text-white font-bold"
                    )}
                  >
                    <Dialog.Title as="h3" className="text-xl capitalize">
                      {title}
                    </Dialog.Title>
                    {showCancel && (
                      <button
                        className="inline-flex items-center justify-center shadow-2xl bg-white h-7 w-7 rounded-full"
                        onClick={onClose}
                      >
                        <XIcon className="h-3 w-3 text-black" />
                      </button>
                    )}
                  </div>
                )}
                <div className="">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;
