import React from 'react'

const JoinUs = () => {
    return (
        <div className="flex space-x-3">
            <button
                className="bg-white rounded-full border border-[#5865F2] px-5 py-2"
                onClick={() => window.open("https://discord.com/invite/VcjDkXAwAt")}
            >
                <div className="flex space-x-2 justify-center items-center">
                    <img src="/assets/images/icons/discord.png" alt="discord" className="max-w-[24px] max-h-[24px]" />
                    <div className="font-dmSans font-bold text-xs text-[#5865F2]">Discord</div>
                </div>
            </button>
            <button
                className="bg-white rounded-full border border-[#1877F2] px-5 py-2"
                onClick={() => window.open("https://www.facebook.com/groups/fownd/")}
            >
                <div className="flex space-x-2 justify-center items-center">
                    <img src="/assets/images/icons/facebook.png" alt="facebook" className="max-w-[24px] max-h-[24px]" />
                    <div className="font-dmSans font-bold text-xs text-[#1877F2]">Facebook</div>
                </div>
            </button>
        </div>
    )
}

export default JoinUs