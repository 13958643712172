import { ScrollToTop } from "../../component/ui";
import About from "./About";
import Footer from "./Footer";
import Intro from "./Intro";
import Header from "./Header";
import Image from "./Image";

const SharePage = () => {
  return (
    <>
      <Header />
      <Intro />
      {/* <Image /> */}
      {/* <Slider /> */}
      {/* <About /> */}
      {/* <Footer /> */}
      {/* <ScrollToTop /> */}
    </>
  );
};

export default SharePage;
