import { useNavigate } from "react-router-dom";

export const AboutUs = () => {
  let navigate = useNavigate();

  return (
    <section className="bg-white lg:bg-gray md:bg-gray lg:h-[28rem]">
      <div className="max-w-3xl mx-auto pt-32">
        <h3 className="font-archivo text-center font-bold lg:text-3xl md:text-3xl text-sm lg:text-white md:text-white text-gray">
          MEET OUR TEAM OF{" "}
          <span className="bg-white text-gray pr-2">
            CREATORS, DESIGNERS AND PROBLEM SOLVERS
          </span>
        </h3>
      </div>
      <div className="lg:px-16 lg:pt-32 md:px-16 md:pt-32 px-2 pt-12 lg:max-w-full lg:justify-center mx-auto">
        <div className="mb-6">
          <div className="max-w-md mb-3 block lg:hidden md:hidden border-[0.5px] border-gray rounded-lg px-2 py-2">
            <div className="flex space-x-3 items-center mb-3">
              <div className="flex-none">
                <img
                  src="/assets/images/teams/vignesh.png"
                  className="w-[110px] h-[162px] object-cover rounded-md"
                  alt="fownd"
                />
              </div>
              <div>
                <div className="text-gray font-archivo font-medium text-base pt-4">
                  VIGNESH RAMA
                </div>
                <div className="text-gray font-archivo font-normal text-xs pt-6">
                  I am a builder, and technology enthusiast at my core, I bring
                  the technologist and architect skillset as a founding member
                  of the team
                </div>
                <div
                  className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
                  onClick={() => navigate("/team/vignesh")}
                >
                  Read more
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-md mb-3 block lg:hidden md:hidden border-[0.5px] border-gray rounded-lg px-2 py-2">
            <div className="flex space-x-3 items-center mb-3">
              <div className="flex-none">
                <img
                  src="/assets/images/teams/chrisina.png"
                  className="w-[110px] h-[162px] object-cover rounded-md"
                  alt="fownd"
                />
              </div>
              <div>
                <div className="text-gray font-archivo font-medium text-base pt-4">
                  {" "}
                  DR.CHRISTINA RAMA
                </div>
                <div className="text-gray font-archivo font-normal text-xs pt-6">
                  Christina is the founding Subject Matter Expert at FOWND with
                  a doctorate in Physical Therapy, multiple certifications, and
                  nearly two decades of experience
                </div>
                <div
                  className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
                  onClick={() => navigate("/team/christina")}
                >
                  Read more
                </div>
              </div>
            </div>
          </div>
          <div className="max-w-md mb-3 block lg:hidden md:hidden border-[0.5px] border-gray rounded-lg px-2 py-2">
            <div className="flex space-x-3 items-center mb-3">
              <div className="flex-none">
                <img
                  src="/assets/images/teams/sowmya.png"
                  className="w-[110px] h-[162px] object-cover rounded-md"
                  alt="fownd"
                />
              </div>
              <div>
                <div className="text-gray font-archivo font-medium text-base pt-4">
                  {" "}
                  SOWMYA SHETTY
                </div>
                <div className="text-gray font-archivo font-normal text-xs pt-6">
                  A lifelong goal to better people’s lives is what connects her
                  to FOWND and its core value. Sowmya is a dentist by education,
                  a homemaker and a mother by choice, and an entrepreneur in
                  making.
                </div>
                <div
                  className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
                  onClick={() => navigate("/team/sowmya")}
                >
                  Read more
                </div>
              </div>
            </div>
          </div>
          {/* <div className="max-w-md mb-6 block lg:hidden md:hidden border-[0.5px] border-gray rounded-lg px-2 py-2">
            <div className="flex space-x-3 items-center mb-3">
              <div className="flex-none">
                <img
                  src="/assets/images/teams/parini.png"
                  className="w-[110px] h-[162px] object-cover rounded-md"
                  alt="fownd"
                />
              </div>
              <div>
                <div className="text-gray font-archivo font-medium text-base pt-4">
                  PARINI PAREKH
                </div>
                <div className="text-gray font-archivo font-normal text-xs pt-6">
                  A passionate UX designer, problem solver and design
                  enthusiast, I show alacrity towards any design challenge and
                  like to master it in depth. FOWND gave me a way to
                  explore,push boundaries and dive into endless possibilities.
                </div>
                <div
                  className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
                  onClick={() => navigate("/team/parini")}
                >
                  Read more
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="max-w-md mb-6 block lg:hidden md:hidden border-[0.5px] border-gray rounded-lg px-2 py-2">
            <div className="flex space-x-3 items-center mb-3">
              <div className="flex-none">
                <img
                  src="/assets/images/teams/amy-elder.png"
                  className="w-[110px] h-[162px] object-cover rounded-md"
                  alt="fownd"
                />
              </div>
              <div>
                <div className="text-gray font-archivo font-medium text-base pt-4">
                DR.AMY ELDER
                </div>
                <div className="text-gray font-archivo font-normal text-xs pt-6">
                  Amy is a Clinical Documentation Specialist and have more than
                  a decade experience in physical therapy. She is helping fownd
                  to revolutionise the field of physical therapy.
                </div>
                <div
                  className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
                  onClick={() => navigate("/team/amy")}
                >
                  Read more
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className="lg:px-32 lg:grid lg:grid-cols-4 lg:gap-5 md:grid md:grid-cols-2 md:gap-4 hidden">
          <div className="max-w-[223px] mb-6">
            <img
              src="/assets/images/teams/vignesh.png"
              className="w-full rounded-[20px]"
              alt="fownd"
            />
            <div className="text-gray md:text-white lg:text-gray font-archivo font-medium text-base pt-4">
              VIGNESH RAMA
            </div>
            <div className="text-gray md:text-white lg:text-gray font-archivo font-normal text-xs pt-6">
              I am a builder, and technology enthusiast at my core, I bring the
              technologist and architect skillset as a founding member of the
              team
            </div>
            <div
              className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
              onClick={() => navigate("/team/vignesh")}
            >
              Read more
            </div>
          </div>
          <div className="max-w-[223px] mb-6">
            <img
              src="/assets/images/teams/chrisina.png"
              className="w-full rounded-[20px]"
              alt="fownd"
            />
            <div className="text-gray md:text-white lg:text-gray font-archivo font-medium text-base pt-4">
              {" "}
              CHRISTINA RAMA
            </div>
            <div className="text-gray md:text-white lg:text-gray font-archivo font-normal text-xs pt-6">
              Christina is the founding Subject Matter Expert at FOWND with a
              doctorate in Physical Therapy, multiple certifications, and nearly
              two decades of experience
            </div>
            <div
              className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
              onClick={() => navigate("/team/christina")}
            >
              Read more
            </div>
          </div>
          <div className="max-w-[223px] mb-6">
            <img
              src="/assets/images/teams/sowmya.png"
              className="w-full rounded-[20px]"
              alt="fownd"
            />
            <div className="text-gray md:text-white lg:text-gray font-archivo font-medium text-base pt-4">
              {" "}
              SOWMYA SHETTY
            </div>
            <div className="text-gray md:text-white lg:text-gray font-archivo font-normal text-xs pt-6">
              A lifelong goal to better people’s lives is what connects her to
              FOWND and its core value. Sowmya is a dentist by education, a
              homemaker and a mother by choice, and an entrepreneur in making.
            </div>
            <div
              className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
              onClick={() => navigate("/team/sowmya")}
            >
              Read more
            </div>
          </div>
          {/* <div className="max-w-[223px] mb-6">
            <img
              src="/assets/images/teams/parini.png"
              className="w-full rounded-[20px]"
              alt="fownd"
            />
            <div className="text-gray md:text-white lg:text-gray font-archivo font-medium text-base pt-4">
              PARINI PAREKH
            </div>
            <div className="text-gray md:text-white lg:text-gray font-archivo font-normal text-xs pt-6">
              A passionate UX designer, problem solver and design enthusiast, I
              show alacrity towards any design challenge and like to master it
              in depth. FOWND gave me a way to explore,push boundaries and dive
              into endless possibilities.
            </div>
            <div
              className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
              onClick={() => navigate("/team/parini")}
            >
              Read more
            </div>
          </div> */}
          {/* <div className="max-w-[223px] mb-6">
            <img
              src="/assets/images/teams/amy-elder.png"
              className="w-full rounded-[20px]"
              alt="fownd"
            />
            <div className="text-gray md:text-white lg:text-gray font-archivo font-medium text-base pt-4">
              DR.AMY ELDER
            </div>
            <div className="text-gray md:text-white lg:text-gray font-archivo font-normal text-xs pt-6">
              Amy is a Clinical Documentation Specialist and have more than a
              decade experience in physical therapy. She is helping fownd to
              revolutionise the field of physical therapy.
            </div>
            <div
              className="text-[#DECD84] font-archivo font-bold text-xs pt-6 underline cursor-pointer"
              onClick={() => navigate("/team/amy")}
            >
              Read more
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
