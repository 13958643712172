import React from 'react'

const StarIcon = ({ ...props }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" { ...props }>
            <path d="M8 0.5L9.19183 4.92651C9.33727 5.46667 9.40998 5.73674 9.55352 5.95754C9.68053 6.15291 9.84709 6.31947 10.0425 6.44648C10.2633 6.59002 10.5333 6.66273 11.0735 6.80817L15.5 8L11.0735 9.19183C10.5333 9.33727 10.2633 9.40998 10.0425 9.55352C9.84709 9.68053 9.68053 9.84709 9.55352 10.0425C9.40998 10.2633 9.33727 10.5333 9.19183 11.0735L8 15.5L6.80817 11.0735C6.66273 10.5333 6.59002 10.2633 6.44648 10.0425C6.31947 9.84709 6.15291 9.68053 5.95754 9.55352C5.73674 9.40998 5.46667 9.33727 4.92651 9.19183L0.5 8L4.92651 6.80817C5.46667 6.66273 5.73674 6.59002 5.95754 6.44648C6.15291 6.31947 6.31947 6.15291 6.44648 5.95754C6.59002 5.73674 6.66273 5.46667 6.80817 4.92651L8 0.5Z" fill="#DECD84" stroke="#DECD84" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default StarIcon