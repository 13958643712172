import { FormEvent, useContext, useEffect, useState } from "react"
import { useMultistepForm } from "../Hooks/useMultistepForm"
import { StepForm } from "../component/ui/MultiStepForm/Step"
import axios from "axios"
import url from "../config"
import { toast } from "react-hot-toast"
import { ItemsContext } from "../data/context"
import { useNavigate } from "react-router-dom"
import { LoadingCircle } from "../component/ui"
import Progressbar from "../component/ui/Progressbar/Progressbar"
import clsx from "clsx";

type FormData = {
    question1: any
    question2: any
    question3: any
    question4: any
    question5: any
    question6: any
    question7: any
    question8: any
    question9: any
    question10: any
}

function MultiStepForm() {

    const [data, setData] = useState([]);
    const { items, ptQuestion } = useContext(ItemsContext);
    const [loading, setLoading] = useState(false);
    const [fowndUser, setFowndUser] = useState("There");
    const [ipAddress, setIPAddress] = useState("");
    const [state, setState] = useState("");
    let navigate = useNavigate();

    function updateFields(fields: Partial<FormData>) {
        setData(prev => {
            return { ...prev, ...fields }
        })
    }

    useEffect(() => {
        setLoading(true)
        let fowndData: any = localStorage.getItem("fownd");
        fowndData = (JSON.parse(fowndData))
        // if (fowndData?.name) {
        //     setFowndUser(fowndData?.name);
        //     const body = {
        //         email: fowndData?.email
        //     }
        //     axios
        //         .post(`${url}/take-survey-check`, body)
        //         .then((res) => {
        //             if (res?.data?.isTakeSurvey) {
        //                 axios
        //                     .get(`${url}/survey`)
        //                     .then((res) => {
        //                         setLoading(false)
        //                         setData(res?.data[1]?.pages[0]?.elements);
        //                     })
        //                     .catch((err) => {
        //                         console.log(err, "err");
        //                         setLoading(false)
        //                         toast.error("Something went Wrong");
        //                     });
        //             } else {
        //                 setLoading(false)
        //                 toast.error("You have already submitted your survey!");
        //                 navigate("/")
        //             }
        //         })
        //         .catch((err) => {
        //             console.log(err, "err");
        //             setLoading(false)
        //             toast.error("Something went Wrong");
        //         });
        // } else {
        axios
            .get(`${url}/survey`)
            .then((res) => {
                setLoading(false)
                const elements = res?.data[1]?.pages[0]?.elements;

                // Custom sorting function to prioritize "Basic Question" items
                function customSort(a: any, b: any) {
                    if (Array.isArray(a)) {
                        return -1; // Move "Basic Question" before other items
                    } else if (Array.isArray(b)) {
                        return 1; // Move other items after "Basic Question"
                    }
                    return 0; // Maintain original order if both are "Basic Question" or both are not
                }

                // Sort the elements array based on customSort function
                elements.sort(customSort);
                setData(elements);
            })
            .catch((err) => {
                console.log(err, "err");
                setLoading(false)
                toast.error("Something went Wrong");
            });
        // }
    }, [navigate]);

    const formsComponent = () => {
        const data1 = data?.map((result: any, index: any) => {
            return (
                <StepForm {...result} question={result} updateFields={updateFields} key={index} />
            );
        })
        return data1
    }

    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } =
        useMultistepForm(formsComponent())

    function onSubmit(e: FormEvent) {
        e.preventDefault()
        if (!isLastStep) {
            return next()
        }
        setLoading(true)
        const myObject = items.reduce((acc: any, obj) => {
            acc[obj.name] = obj.key;
            return acc;
        }, {} as { [key: string]: number });
        let fowndData: any = localStorage.getItem("fownd");
        fowndData = (JSON.parse(fowndData))
        let body: any = {
            surveyResult: myObject,
            surveyResultText: JSON.stringify(myObject),
            state: state,
            ipAddress: ipAddress,
            // ptQuestion: ptQuestion
        }
        if (fowndData?.email) {
            body.email = fowndData?.email
        }
        console.log(body)
        axios
            .post(`${url}/survey`, body)
            .then((res) => {
                setLoading(false)
                toast.success("Survey Submitted Successfully");
                navigate("/success")
            })
            .catch((err) => {
                console.log(err, "err");
                setLoading(false)
                toast.error("Something went Wrong");
            });
    }

    useEffect(() => {
        const fetchIPAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIPAddress(response.data.ip);
                const ipAddress = await axios.get("https://api64.ipify.org/?format=json")
                const latlong = await axios.get(`https://ipapi.co/${ipAddress?.data?.ip}/json`);
                await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlong.data?.latitude},${latlong.data?.longitude}&key=AIzaSyBH-06rTud2tbw9Oi_r7kKL_z_mq5yzsJg`)
                    .then(response => {
                        const results: any = response?.data?.results;
                        if (results && results.length > 0) {
                            // Extract the state and country from the response
                            const addressComponents = results[0].address_components;
                            let state, country;
                            for (const component of addressComponents) {
                                const types = component.types;
                                if (types.includes('administrative_area_level_1')) {
                                    state = component.long_name;
                                } else if (types.includes('country')) {
                                    country = component.long_name;
                                }
                            }
                            setState(state);
                            // console.log('State:', state);
                            console.log('Country:', country);
                        } else {
                            console.log('No results found');
                        }
                    })
                    .catch(error => {
                        console.error('Reverse geocoding error:', error);
                    });
            } catch (error) {
                console.log('Error fetching IP address:', error);
            }
        };
        fetchIPAddress();
    }, []);

    return (
        <div className="h-screen">
            <div className="md:h-auto xl:h-screen">
                <div className="grid lg:grid-cols-6">
                    <div className="lg:col-span-2 bg-gray hidden lg:block h-screen">
                        <div className="flex h-screen w-full justify-center items-center">
                            <img src="/assets/images/survey/survey.svg" alt="survey" />
                        </div>
                    </div>
                    <div className="lg:col-span-4">
                        <div className="h-screen w-full justify-start items-justify-start">
                            <div className="justify-center items-center w-full">
                                {currentStepIndex === 0 && (
                                    <div className="lg:px-16 lg:py-16 px-4 py-8">
                                        <h2 className="mb-8 font-dmSans text-gray font-bold text-xl">Hi there, we would love your feedback with a quick 2 minute survey. We will show you survey data along with a chance to win a $100 digital gift card!</h2>
                                    </div>
                                )}
                                <form onSubmit={onSubmit} className={clsx(currentStepIndex === 0 ?
                                    "mt-0" : "mt-32",
                                    "bg-porcelain lg:px-16 lg:py-16 px-4 py-8")}>
                                    {loading ?
                                        <div className="lg:px-16 lg:py-16 px-4 py-8">
                                            <LoadingCircle />
                                        </div> :
                                        <div>
                                            <div className="lg:gird lg:grid-cols-2">
                                                <div className="font-dmSans text-gray font-normal text-base mb-3">
                                                    {currentStepIndex + 1} / {steps.length}
                                                </div>
                                                <div className="w-full bg-[#FFFFFF] rounded-full h-2.5 mb-4">
                                                    <Progressbar width={`${(currentStepIndex + 1) * 10}%`} />
                                                </div>
                                                <div className="mt-9">
                                                    {currentStepIndex === 0 && (
                                                        <div>
                                                            <h2 className="mb-2 font-dmSans text-gray font-bold text-xl">$100 Amazon Gift Card Raffle Entry</h2>
                                                            <div className="mb-2 font-dmSans text-gray font-bold text-xs">Your email, name and data will not be shared, sold or spammed by Fownd Inc. Data will solely used for raffle entry and Survey Analysis</div>
                                                        </div>
                                                    )}
                                                </div>
                                                {step}
                                            </div>
                                            <div className="flex space-x-3 items-start justify-start mt-4">
                                                {!isFirstStep && (
                                                    <button
                                                        type="button"
                                                        onClick={back}
                                                        className="bg-acapulco text-white font-dmSans text-xl font-bold px-4 py-4 rounded-lg"
                                                    >
                                                        Back
                                                    </button>
                                                )}
                                                <button
                                                    type="submit"
                                                    className="bg-acapulco text-white font-dmSans text-xl font-bold px-4 py-4 rounded-lg"
                                                >
                                                    {isLastStep ? "Complete" : "Next  >>>"}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default MultiStepForm