import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "../../component/ui";

const SurveyStart = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");

  let fowndData: any = localStorage.getItem("fownd");

  return (
    <div className="flex justify-center items-center">
      <div className="md:pt-56 pt-0 md:h-auto xl:h-screen flex justify-center items-center">
        <div className="relative">
          <img
            className=" h-52 md:h-52 lg:h-60 xl:h-80 w-auto m-auto"
            src="/assets/images/logo/wotermark.png"
            alt="logo"
          />
          <div className="max-w-[300px] left-2 md:left-[7.5rem] lg:left-44 font-inter font-bold text-5xl absolute lg:top-28 top-16 capitalize text-center">
            what do you think?
          </div>
          <div className="font-dmSans font-medium text-xl text-center p-2 max-w-2xl">
            Leave us your email with this 1 minute survey for your free $5
            Starbucks gift card. We will also enter you into a chance to win
            $100 Amazon gift card.
          </div>
          <div className="flex justify-center my-5">
            <img src="/assets/images/icons/gift-card.png" alt="fownd" />
          </div>
          <div className="px-4">
            <InputText
              title={"Email address"}
              placeholder={""}
              className="w-full"
              required
              onChange={(e) => setEmail(e)}
            />
          </div>
          <div className="p-2 flex flex-col justify-center mt-16 md:mt-28 lg:mt-3 space-y-3">
            <button
              className="p-4 inline-flex items-center text-white font-dmSans text-xl justify-center shadow-2xl bg-gray h-12 rounded-full border border-gray"
              onClick={() => {
                if (!fowndData && email !== "") {
                  localStorage.setItem(
                    "fownd",
                    JSON.stringify({ email: email })
                  );
                }
                localStorage.setItem("fownd-PT", "true");
                navigate("/disclaimer?survey=true");
              }}
            >
              Survey For Physical Therapists
            </button>
            <button
              className="p-4 inline-flex items-center text-gray font-dmSans text-xl justify-center shadow-2xl bg-white h-12 rounded-full border border-gray"
              onClick={() => {
                if (!fowndData && email !== "") {
                  localStorage.setItem(
                    "fownd",
                    JSON.stringify({ email: email })
                  );
                }
                localStorage.setItem(
                  "fownd",
                  JSON.stringify({ email: email })
                );
                localStorage.setItem("fownd-NONPT", "true");
                navigate("/disclaimer?non-pt-survey=true");
              }}
            >
              General Platform Survey
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyStart;
