import React, { useEffect, useState, useCallback } from 'react'

import clsx from 'clsx';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import url from '../config';
import { Modal } from '../component/ui/Modal';
import { StarIcon } from '../component/Icons';
import { LoadingCircle } from '../component/ui';
import BarChart from '../component/ui/Charts/BarChart';
import PieChart from '../component/ui/Charts/PieChart';

const Graph = () => {

    let navigate = useNavigate();
    const [chartData, setChartData] = useState({
        question1: 0,
        question3: 0,
        barGraph: {
            "x": [],
            "y": [],
            "type": "bar"
        },
        pieGraph: {
            "labels": [],
            "datasets": [],
            "type": "pie"
        },
        question5: 0,
        question6: 0,
        question8: 0,
        totalSurvey: 0
    })
    const [comment, setComment] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [showModel, setShowModel] = React.useState(false);

    const fetchChartData = useCallback(
        async () => {
            await axios
                .get(`${url}/graph`)
                .then((res) => {
                    setChartData(res?.data)
                })
                .catch((err) => {
                    toast.error("Something went Wrong");
                });
        },
        [setChartData],
    )

    const onSubmit = () => {
        setLoading(true)
        const body = {
            comment: comment
        };
        axios
            .post(`${url}/comment`, body)
            .then((res) => {
                setLoading(false);
                toast.success("Message Sent Successfully!!");
                setShowModel(true);
            })
            .catch((err) => {
                console.log(err, "err");
                setLoading(false)
                toast.error("Something went Wrong");
            });
    }

    useEffect(() => {
        fetchChartData()
    }, [fetchChartData]);

    return (
        <section >
            <div className="flex flex-col lg:flex-row 2xl:h-screen">
                <div className="w-full lg:w-3/4 bg-gray">
                    <div className="px-3 lg:px-9 pt-20">
                        <div className="text-center text-white">
                            <div className="font-bold text-2xl">Thank you!</div>
                            <div className="font-normal text-xl">Here is what your peers are saying</div>
                            <div className="flex space-x-3 justify-center items-center font-dmSans pt-10">
                                <div className="font-bold text-base font-archivo rounded-md border-white border-[1px] px-3 py-px-3">{chartData?.totalSurvey}</div>
                                <div className="font-bold text-base font-archivo">People have taken this survey</div>
                            </div>
                        </div>
                        <div className="flex justify-center mt-2 lg:mt-16">
                            <div className="px-3 py-5 grid grid-cols-1 md:grid-cols-2 gap-4 justify-center border-[#E6E6E6] border-[0.5px] rounded-lg">
                                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-4">
                                    <div className="px-2 py-2 bg-regent-gray max-w-xs rounded-lg">
                                        {chartData && (
                                            <PieChart
                                                values={chartData?.pieGraph?.datasets}
                                                label={chartData?.pieGraph?.labels}
                                                id="time-to-evaluate"
                                            />
                                        )}
                                    </div>
                                    <div className="px-2 py-2 bg-regent-gray max-w-xs rounded-lg">
                                        {chartData && (
                                            <BarChart
                                                values={chartData?.barGraph?.y}
                                                label={chartData?.barGraph?.x}
                                                id="age-chart"
                                            />
                                            // <Test chartData={data} />
                                        )}
                                    </div>
                                    {/* <BarChart1 data={chartData?.barGraph} /> */}
                                    <div className="px-2 py-2 bg-regent-gray max-w-xs rounded-lg text-white">
                                        <div className="text-xs text-center lg:text-base font-dmSans font-normal">
                                            Insufficient Care Time
                                        </div>
                                        <div className="text-base lg:text-2xl font-normal font-dmSans text-center mt-3">
                                            {chartData?.question1 > 0 && chartData?.question1 < 100 ? chartData?.question1?.toFixed(2) : chartData?.question1}%
                                        </div>
                                    </div>
                                    <div className="px-2 py-2 bg-regent-gray max-w-xs rounded-lg text-white">
                                        <div className="text-xs text-center lg:text-base font-dmSans font-normal">
                                            Schedule availability
                                        </div>
                                        <div className="text-base lg:text-2xl font-normal font-dmSans text-center mt-3">
                                            {chartData?.question3 > 0 && chartData?.question3 < 100 ? chartData?.question3?.toFixed(2) : chartData?.question3}%
                                        </div>
                                        <div className="hidden lg:block text-xs text-center font-dmSans font-normal">
                                            Survey Takers can choose their work schedule
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-regent-gray rounded-lg lg:px-11 px-5 py-4">
                                    <div className="flex items-center justify-center">
                                        <img src="/assets/images/icons/chart-line.png" alt="found-chat" />
                                    </div>
                                    <div className="mt-5">
                                        <div className="text-center text-white font-dmSans font-bold lg:text-base text-sm">
                                            FOWND- Moving, Physical therapy forward
                                        </div>
                                        <div className="text-white mt-4 lg:mt-11">
                                            <div className="">
                                                <div className="flex space-x-2 lg:flex-row mb-3">
                                                    <div className="w-[90px] text-2xl font-dmSans font-normal">
                                                        {chartData?.question5 > 0 && chartData?.question5 < 100 ? chartData?.question5?.toFixed(2) : chartData?.question5}%
                                                    </div>
                                                    <div className="w-3/4 text-base font-dmSans font-normal">
                                                        think PT everywhere platform is valuable
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="flex space-x-2 lg:flex-row mb-3">
                                                    <div className="w-[90px] text-2xl font-dmSans font-normal">
                                                        {chartData?.question6 > 0 && chartData?.question6 < 100 ? chartData?.question6 : chartData?.question6}
                                                    </div>
                                                    <div className="w-3/4 text-base font-dmSans font-normal">
                                                        of the responders would practice on FOWND
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="flex space-x-2 lg:flex-row mb-3">
                                                    <div className="w-[90px] text-2xl font-dmSans font-normal">
                                                        {chartData?.question8 > 0 && chartData?.question8 < 100 ? chartData?.question8?.toFixed(2) : chartData?.question8}%
                                                    </div>
                                                    <div className="w-3/4 text-base font-dmSans font-normal">
                                                        responders think gig-economy features would improve PT and Patient Satisfaction
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/4 lg:bg-porcelain bg-gray">
                    <div className="px-3 lg:pb-11 pt-2 lg:pt-20 lg:px-6 flex flex-col justify-center lg:items-start items-center">
                        <div className="text-base font-dmSans font-bold lg:text-gray text-white">
                            Join in at the ground level! Help us improve the platform
                        </div>
                        <div className="lg:grid lg:space-x-0 hidden">
                            <div className="rounded-lg bg-white max-w-xs px-3 py-5 my-4">
                                <div className="flex items-center space-x-5 pb-4 border-b border-gray">
                                    <img src="/assets/images/icons/discord.png" alt="discord" />
                                    <div className="font-dmSans font-bold text-base text-black">
                                        Discord
                                    </div>
                                </div>
                                <div className="flex items-center space-x-5 pb-2 mt-5">
                                    <StarIcon />
                                    <div className="font-dmSans font-normal text-xs text-black">Securely communicate, Influence features and test the platform</div>
                                </div>
                                <div className="mt-3 flex flex-col justify-center items-center">
                                    <button
                                        className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full w-full h-9"
                                        onClick={() => window.open("https:discord.gg/VcjDkXAwAt")}
                                    >
                                        Join
                                    </button>
                                    <div className="flex items-center">
                                        <div>
                                            <img src="/assets/images/icons/light-bulb.svg" alt="light-bulb" />
                                        </div>
                                        <div
                                            className="text-[#6C63FF] font-dmSans font-bold text-xs underline"
                                            onClick={() => window.open("https:www.youtube.com/watch?v=TJ13BA3-NR4")}
                                        >
                                            Learn about discord
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-lg bg-white max-w-xs px-9 py-5 mb-4">
                                <div className="flex items-center space-x-5 pb-4 border-b border-gray">
                                    <img src="/assets/images/icons/facebook.png" alt="discord" />
                                    <div className="font-dmSans font-bold lg:text-base text-[8px] text-black">
                                        Facebook
                                    </div>
                                </div>
                                <div className="flex items-center justify-center space-x-5 pb-2 mt-5 mb-4">
                                    <div className="font-dmSans font-normal lg:text-xs text-[8px] text-black">Support us, keep in touch and be in the know</div>
                                </div>
                                <div className="mt-3">
                                    <button
                                        className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full w-full h-9"
                                        onClick={() => window.open("http://www.facebook.com/groups/fownd/")}
                                    >
                                        Join
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 gap-2 lg:hidden">
                            <div className="rounded-lg bg-white px-3 py-5 my-3">
                                <div className="flex space-x-3 items-center justify-center">
                                    <img src="/assets/images/icons/discord.png" alt="discord"/>
                                    <div className="font-dmSans font-bold text-[18px] text-black">
                                        Discord
                                    </div>
                                </div>
                                <div className="flex space-x-3 pb-2 mt-5">
                                    <StarIcon />
                                    <div>Securely communicate, Influence and test the platform</div>
                                </div>
                                <div className="mt-6">
                                    <button
                                        className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full w-full h-9"
                                        onClick={() => window.open("https:discord.gg/VcjDkXAwAt")}
                                    >
                                        Join
                                    </button>
                                    <div className="flex items-center">
                                        <div>
                                            <img src="/assets/images/icons/light-bulb.svg" alt="light-bulb" />
                                        </div>
                                        <div
                                            className="text-[#6C63FF] font-dmSans font-bold text-[8px] underline"
                                            onClick={() => window.open("https:www.youtube.com/watch?v=TJ13BA3-NR4")}
                                        >
                                            What's discord in 2 min
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="rounded-lg bg-white px-3 py-5 my-3">
                                <div className="flex space-x-3 items-center justify-center">
                                    <img src="/assets/images/icons/facebook.png" alt="facsebook" />
                                    <div className="font-dmSans font-bold text-[18px] text-black">
                                        Facebook
                                    </div>
                                </div>
                                <div className="flex space-x-3 pb-2 mt-5">
                                    <StarIcon />
                                    <div>Support us, keep in touch and be in the know</div>
                                </div>
                                <div className="mt-8">
                                    <button
                                        className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full w-full h-9"
                                        onClick={() => window.open("http://www.facebook.com/groups/fownd")}
                                    >
                                        Join
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            <textarea className="rounded-lg px-3 py-3 w-full lg:max-w-xs" rows={4} placeholder="Leave us a comment" onChange={(e) => setComment(e.target.value)} />
                        </div>
                        <div>
                            <button
                                className={clsx(loading ? "w-20 cursor-not-allowed" : "cursor-pointer",
                                    "bg-acapulco text-base w-24 font-bold font-dmSans text-white rounded-full h-9 mt-2 px-3 mb-12 flex justify-center items-center")}
                                onClick={() => onSubmit()}
                            >
                                {loading && <LoadingCircle />}
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="" show={showModel} onClose={() => navigate("/")}>
                <div className="px-10 mb-3">
                    <h3 className="font-dmSans font-bold max-w-xl text-center m-auto text-xl mb-5 capitalize">
                        thank you for showing interest in fownd
                    </h3>
                    <div className="flex justify-center items-center">
                        <button
                            type="button"
                            className="bg-gray inline-flex h-10 select-none items-center justify-center w-40 text-white px-7 font-medium leading-5 outline-none rounded-full cursor-pointer"
                            onClick={() =>
                                navigate("/")
                            }>
                            Home
                        </button>
                    </div>
                </div>
            </Modal>
        </section >
    )
}

export default Graph