import { ReactNode } from "react"

type FormWrapperProps = {
    title: string
    children: ReactNode
}

const FormWrapper = ({ title, children }: FormWrapperProps) => {
    return (
        <>
            <h2 className="mb-8 font-dmSans text-gray font-bold text-xl">
                {title}
            </h2>
            <div className="grid gap-4">
                {children}
            </div>
        </>
    )
}

export default FormWrapper;