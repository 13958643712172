import React, { useRef } from "react";
import useChart from "../../../Hooks/useChart";

export interface BarChartProps {
    className?: any;
    values: string[];
    label: string[];
    id: string;
}

const BarChart: React.FC<BarChartProps> = (props) => {

    const {
        className,
        values,
        label,
        id,
        ...rest
    } = props;
    const canvasRef = useRef(null);

    useChart(canvasRef, {
        type: "bar",
        data: {
            labels: label,
            datasets: [
                {
                    label: "",
                    data: values,
                    backgroundColor: ["#DECD84"],
                    borderColor: "#DECD84",
                    borderWidth: 1,
                    borderRadius: 5,
                    borderSkipped: false,
                },
            ],
        },
        options: {
            responsive: true,
            indexAxis: 'y',
            legend: {
                display: true,
                labels: {
                    fontColor: "#ffffff",
                },
            },
            scales: {
                x: {
                    beginAtZero: true,
                    ticks: {
                        color: '#ffffff',
                        display: true,
                    },
                    grid: {
                        display: false, // Hide the horizontal grid lines
                        // drawBorder: false,
                        // drawOnChartArea: false,
                        // color: "rgba(255, 255, 255, 0.24)",
                        // backgroundColor: "rgba(255, 255, 255, 0.24)"
                    },
                },
                y: {
                    beginAtZero: true,
                    ticks: {
                        color: '#ffffff',
                        display: true,
                    },
                    grid: {
                        // color: " #ffffff",
                        // backgroundColor: "rgba(255, 255, 255, 0.24)",
                        display: false, // Hide the vertical grid lines
                        // drawBorder: false,
                        // drawOnChartArea: false
                    },
                }
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
        },
    });

    return (
        <div className="App pr-4">
            <h1 className="text-xs text-center lg:text-base font-dmSans font-normal text-white">
                Challenges <span className="hidden lg:block md:block">starting a PT clinic</span>
            </h1>
            <canvas id={id} ref={canvasRef} {...rest} />
        </div>
    );
}

export default BarChart;
