import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const onTransitionEnd = () => {
      // Scroll to top after the page transition is complete
      window.scrollTo(0, 0);
      window.removeEventListener("pagehide", onTransitionEnd);
    };

    // Add an event listener for the "pagehide" event
    window.addEventListener("pagehide", onTransitionEnd);

    // Scroll to top after a short delay to ensure the page transition is complete
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
      window.removeEventListener("pagehide", onTransitionEnd);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("pagehide", onTransitionEnd);
    };
  }, [pathname]);

  return null;
}

export default ScrollToTop;
