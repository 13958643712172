import React, { useEffect } from 'react'
import { Button } from '../component/ui'
import { useNavigate, useSearchParams } from 'react-router-dom';

const Disclaimer = () => {

    let navigate = useNavigate();
    let [queryParams] = useSearchParams();
    const navigateRoute = () => {
        if (queryParams.get("survey")) {
            navigate("/survey")
        }
        if (queryParams.get("non-pt-survey")) {
            navigate("/non-pt-survey")
        }
    }

    return (
        <div>
            <div className="flex flex-col justify-center items-center md:pt-0 lg:pt-[88px] pt-[16px]">
                <form className="mb-3">
                    <div className="bg-porcelain rounded-lg max-w-2xl mt-16 mx-auto">
                        <div className="shadow-lg px-2 py-2 flex justify-end space-x-3">
                            <button
                                className="p-4 inline-flex items-center text-gray font-dmSans text-base h-10 justify-center shadow-2xl bg-white rounded-full border border-gray"
                                onClick={() => navigate("/")}
                            >
                                Deny
                            </button>
                            <button
                                className="p-4 inline-flex items-center text-white font-dmSans text-base h-10 justify-center shadow-2xl bg-gray rounded-full border border-gray"
                                onClick={() => navigateRoute()}
                            >
                                Accept
                            </button>
                        </div>
                        <div className="mt-5 px-10 py-10 overflow-y-auto lg:h-[600px] h-[545px]">
                            <div className="font-dmSans font-medium text-base p-2">
                                <p className="mb-2">Amazon Gift Card Giveaway</p>
                                <p className="mb-2">OFFICIAL RULES</p>
                                <p className="mb-2">NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. OPEN TO LEGAL RESIDENTS OF THE UNITED STATES, PUERTO RICO OR CANADA WHO ARE AT LEAST 18 YEARS OLD OR AGE OF MAJORITY, WHICHEVER IS OLDER IN YOUR STATE OF RESIDENCE, AT DATE OF ENTRY INTO SWEEPSTAKES. VOID IN ALL OTHER U.S. TERRITORIES AND POSSESSIONS AND WHERE PROHIBITED BY LAW. SWEEPSTAKES ONLY VALID FOR INDIVIDUALS PROVIDING EMAIL ADDRESS AS PART OF SURVEY SUBMISSION</p>
                                <p className="mb-2">Administrator, Sponsor & Prize Provider: Fownd.Inc, Delaware,  USA </p>
                                <p className="mb-2">THIS SWEEPSTAKES IS NOT SPONSORED, ENDORSED OR ADMINISTERED BY AMAZON.</p>
                                <p className="mb-2">1. Acceptance of Rules. By entering the "FOWND SURVEY AMAZON SWEAPSTAKE" (the "Sweepstakes") operated on "https://fownd.care," you hereby accept and agree to these official rules (the "Official Rules") of this Sweepstakes and the decisions of the Administrator in connection with this Sweepstakes, whose decisions are final.</p>
                                <p className="mb-2">No purchase, entry fee or use of wireless device is necessary to enter or win. If you are a wireless service subscriber and have a smartphone, tablet or other wireless device that allows you access to the Internet, you may also enter this Sweepstakes via any of those devices during the Promotion Period (as defined below). If you chose to access this Sweepstakes via any of those wireless devices, you may be charged data rates in accordance to the Terms and Conditions of the Service Agreement with your carrier.</p>
                                <p className="mb-2">2. Eligibility. This Sweepstakes is offered and open to persons who are: (a) legal residents of the United States; (b) at least 18 years old or age of majority, whichever is older in their state of residence, at the date of entry in this Sweepstakes. Employees, directors, and immediate family members of the Sweepstakes Entities and their parents, affiliated and subsidiary companies, advertising and promotion agencies and legal and financial advisors are not eligible to participate in this Sweepstakes. For purpose of the foregoing, "immediate family" refers to parents, spouses, children, siblings or any members of the same households of such employees and directors. This Sweepstakes is void in all other U.S. territories and possessions, and where prohibited by law. </p>
                                <p className="mb-2">SWEEPSTAKES ONLY VALID FOR INDIVIDUALS SUBMITTING A SURVEY AND PROVIDING EMAIL ADDRESS</p>
                                <p className="mb-2">3. Entering the Sweepstakes: To receive an entry into the Sweepstakes, users need to: (i) access, during the Promotion Period, the Sweepstakes survey found on the FOWND website, accessible at https://fownd.care/ (ii) sign up for the Sweepstakes by completing the survey on that web page; (iii) agree to these Official Rules; and (iv) hit the "Submit" button in the survey. There is no cost to register to the FOWND website at any time. Participants may not receive additional entries into the Sweepstakes.</p>
                                <p className="mb-2">4. Promotion Period. The Sweepstakes begins at 10:30 a.m. Eastern Time (“ET”) on December 5th 2023, and ends at 05:00 p.m. ET on March 31st, 2024(the "Promotion Period").</p>
                                <p className="mb-2">5. Prize. The Sweepstakes will award one (1) grand prize (the "Prize") which consists of one (1) $100 Amazon Gift Card. The Prize has an approximate retail value ("ARV") of ONE HUNDRED DOLLARS (US$100.00).</p>
                                <p className="mb-2">6. Prize Restrictions. Certain terms and restrictions apply on Prize and will be furnished upon Prize claim. No assignment, transfer, conversion to cash or cash redemption or substitution of the Prize is permitted, except Administrator reserves the right to substitute the Prize with a prize of comparable or greater value, should the Prize or part of the Prize become unavailable. Prize is solely for personal use and may not be used in conjunction with any other promotion. All Prize details are at the sole discretion of the Sweepstakes Entities.</p>
                                <p className="mb-2">7. Odds/Drawings/Winner Notification. Odds of winning the Prize depends on the number of eligible entries received during the Promotion Period. The Prize drawing will be conducted at random by Administrator, whose decisions are final and binding in all respects. The potential winner (the "Winner") will be notified by email. Potential Winner will be asked to provide their personal information, such as their name, physical address, date of birth and phone number in order to confirm eligibility and compliance with these Official Rules. If no contact has been made with a potential Winner after a reasonable effort has been made during three (3) business days from the first notification attempt, Administrator will select an alternate potential Winner, as specified above. </p>
                                <p className="mb-2">8. Prize Taxes. All federal, state, local, provincial, and other tax liabilities arising from this Sweepstakes, including, without limitation, winning the Prize (including any Duty fees where applicable), will be the sole responsibility of the Winner.</p>
                                <p className="mb-2">9. Affidavit of Eligibility/Liability Release and Publicity Release. Winner may be required to complete, execute, and return an Affidavit of Eligibility/Liability Release, and where lawful, a Publicity Release (collectively, the "Release Forms"), as well as a W-9 or E-8 tax form (where applicable) within five (5) days of notification of winning. Failure to return Release Forms timely, or if Prize notification or a Prize or part of a Prize is returned as non-deliverable, or if the Winner is found not to be eligible or not in compliance with these Official Rules, may result in disqualification with an alternate Winner selected in accordance with these Official Rules.</p>
                                <p className="mb-2">10. Publicity Release Terms. Except where prohibited by law, the Winner's acceptance of the Prize constitutes permission for the Sweepstakes Entities to use Winner's name, email, likeness, and in all forms of media, in perpetuity, without further compensation.</p>
                                <p className="mb-2">11. Improper Conduct. Administrator, in its sole discretion, may disqualify any entrant from participation in or use of any or all portions of this Sweepstakes, and refuse to award the Prize, if the entrant engages in any conduct Administrator deems to be improper, unfair or otherwise adverse to the operation of the Sweepstakes or detrimental to other entrants of the Sweepstakes. Such improper conduct includes, without limitation, falsifying personal information required during entrant registration or Prize claim, violating any term or condition stated herein, allowing others to use entrant's personal information for the purpose of accumulating entries, or intentionally trying to defraud, reverse engineer, disassemble or otherwise tamper with the computer programs in connection with this Sweepstakes. Winner agrees that Administrator may void the Prize or any part of the Prize that a Winner may have won and/or require the return of the Prize or any part of the Prize that the Winner may have won as a result of such improper conduct. Winner further acknowledges that any forfeiture of the Prize and/or return of the Prize shall in no way prevent Administrator from pursuing other avenues of recourse, such as criminal or civil proceedings in connection with such conduct. WARNING: ANY ATTEMPT TO DELIBERATELY DAMAGE OR UNDERMINE THE LEGITIMATE OPERATION OF THIS SWEEPSTAKES MAY BE A VIOLATION OF CRIMINAL AND CIVIL LAWS, AND SHOULD SUCH AN ATTEMPT BE MADE, THE SWEEPSTAKES ENTITIES RESERVE THE RIGHT TO SEEK DAMAGES OR OTHER REMEDIES FROM ANY SUCH PERSON(S) RESPONSIBLE FOR SUCH ATTEMPT.</p>
                                <p className="mb-2">12. Integrity of Sweepstakes. If, for any reason, the Sweepstakes is not capable of running as planned by reason of infection by computer virus, worms, bugs, tampering, unauthorized intervention, fraud, technical failures, or any other causes which Administrator, in its sole opinion, believes could corrupt or affect the administration, security, fairness, integrity or proper conduct of this Sweepstakes, Administrator reserves the right at its sole discretion to cancel, terminate, modify or suspend this Sweepstakes and select the Winner from entries received prior to the action taken or in such other manner as Administrator may deem fair.</p>
                                <p className="mb-2">13. Lost or Corrupted Entries. The Sweepstakes Entities and their parents, affiliated and subsidiary companies and advertising and promotion agencies, assume no liability and are not responsible for, and you hereby forever waive any rights to any claim in connection with, lost, late, incomplete, corrupted, stolen, misdirected, illegible or postage-due entries or mail, if applicable; or for any computer, telephone, cable, network, satellite, electronic or Internet hardware or software malfunctions, unauthorized human intervention, or the incorrect or inaccurate capture of entry or other information, or the failure to capture any such information. The Sweepstakes Entities and their parents, affiliated and subsidiary companies and advertising and promotion agencies, are not responsible for any incorrect or inaccurate information, whether caused by registration information submitted participant or tampering, hacking or by any of the equipment or programming associated with or utilized in this Sweepstakes, and assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to the Sweepstakes.</p>
                                <p className="mb-2">14. Damaged Property. The Sweepstakes Entities and their parents, affiliated and subsidiary companies and advertising and promotion agencies, assume no liability and are not responsible for, and you hereby forever waive any rights to any claim in connection with, injury or damage to any entrants or to any other person's computer or property related to or resulting from participating in this Sweepstakes or downloading materials from this Sweepstakes.</p>
                                <p className="mb-2">15. Sweepstakes Errors. The Sweepstakes Entities and their parents, affiliated and subsidiary companies and advertising and promotion agencies, assume no liability and are not responsible for, and you hereby forever waive any rights to any claim in connection with errors and/or ambiguity: (a) in the Sweepstakes; (b) in any related advertising or promotions of this Sweepstakes; and/or (c) in these Official Rules. In the event of any ambiguity(s) or error(s) in these Official Rules, Administrator reserves the right to modify these Official Rules for clarification purposes or to correct any such ambiguity(s) or error(s) without materially affecting the terms and conditions of the Sweepstakes.</p>
                                <p className="mb-2">16. Prize and Winner. The Sweepstakes Entities and their parents, affiliated and subsidiary companies and advertising and promotion agencies, assume no liability and are not responsible for, and you/entrant hereby forever waive any rights to any claim in connection with, the selection and announcement of the Winner, the distribution of the Prize, the acceptance/possession and/or use/misuse of any part of the Prize and/or any injury or damage to any entrant's or third person's property related to or resulting from any part of the Prize or any part of this Sweepstakes.</p>
                                <p className="mb-2">17. Release and Indemnity. Each entrant in this Sweepstakes, including, without limitation, the Winner, hereby releases and agrees to hold harmless the Sweepstakes Entities and their parents, affiliates, subsidiaries, advertising and promotion agencies and their respective directors, officers, employees, representatives and agents from any and all liability for any injuries, loss or damage of any kind to person, including death, and property, arising in whole or in part, directly or indirectly, from acceptance, possession, use or misuse of the Prize, participation in this Sweepstakes and participation in any activity related to this Sweepstakes.</p>
                                <p className="mb-2">18. Limitation of Liability. IN NO EVENT WILL THE SWEEPSTAKES ENTITIES, THEIR PARENTS, AFFILIATES, SUBSIDIARIES AND RELATED COMPANIES, THEIR ADVERTISING OR PROMOTION AGENCIES OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, REPRESENTATIVES AND AGENTS, BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES OR LOSSES OF ANY KIND, INCLUDING DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF YOUR ACCESS TO AND USE OF THE SWEEPSTAKES OR THE DOWNLOADING FROM AND/OR PRINTING MATERIAL DOWNLOADED FROM THE SWEEPSTAKES. SOME JURISDICTIONS MAY NOT ALLOW THE LIMITATIONS OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES OR EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR ANY RESTRICTIONS OR LIMITATIONS REGARDING THESE LIMITATIONS OR EXCLUSIONS.</p>
                                <p className="mb-2">19. Disclaimer of Warranties. THE SWEEPSTAKES & PRIZE ARE PROVIDED "AS IS" WITHOUT ANY WARRANTY, EITHER EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A SPECIFIC PURPOSE OR NON-INFRINGEMENT.</p>
                                <p className="mb-2">20. Identity Disputes. In the event of a dispute as to the identity of a winner based on an email address, the winning entry will be declared made by the Authorized Account Holder of the email address submitted at time of entry. For purposes of these Official Rules, "Authorized Account Holder" is defined as the natural person who is assigned to an email address by an Internet access provider, online service provider or other organization (e.g., business, educational, institution, etc.) that is responsible for assigning email addresses for the domain associated with the submitted email address.</p>
                                <p className="mb-2">21. Binding Arbitration. The parties waive all rights to trial in any action or proceeding instituted in connection with these Official Rules and/or this Sweepstakes. Any controversy or claim, arising out of, or relating to, these Official Rules and/or this Sweepstakes shall be settled by binding arbitration in accordance with the commercial arbitration rules. Any such controversy or claim shall be arbitrated on an individual basis and shall not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration shall be conducted in the state of Rhode Island, USA and judgment on the arbitration award may be entered into any court having jurisdiction thereof.</p>
                                <p className="mb-2">22. Governing Law and Jurisdiction. This promotion is governed by and subject to all applicable Laws and regulations (federal, state, local, and provincial) from where this Sweepstakes is open to. In the event of conflict, the Laws of the U.S.A. will govern. Void where prohibited by law. All issues and questions concerning the construction, validity, interpretation and enforceability of these Official Rules, or the rights and obligations of entrant and Administrator in connection with this Sweepstakes, shall be governed by, and construed in accordance with, the laws of the United States, without giving effect to the conflict of laws rules thereof, and any matters or proceedings which are not subject to arbitration, as set forth in Section 21 of these Official Rules and/or for entering any judgment on an arbitration award, shall take place in the state of Rhode Island, USA. </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Disclaimer